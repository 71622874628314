export const calcTypeData = (
  type,
  dataType,
  calcLoansData,
  calcBondsData,
  langCurrent
) => {
  let dataCalc,
    currentVal,
    currentTerm,
    currentPercent,
    currentCurrency,
    currentDescription,
    currentProduct,
    currentCqFormId,
    currentCqButtonId,
    currentCqPageId;

  if (type === "loans") {
    if (dataType === "short") {
      currentCqFormId = "loan_short_form";
      currentCqButtonId = "loan_short_form_button";
    } else {
      dataCalc = calcLoansData;
      currentVal =
        dataCalc.products[dataCalc.currentProduct].currency[
          dataCalc.currentCurrency
        ].currentVal;
      currentTerm =
        dataCalc.products[dataCalc.currentProduct].currency[
          dataCalc.currentCurrency
        ].terms[dataCalc.currentTerm];
      currentPercent =
        dataCalc.products[dataCalc.currentProduct].currency[
          dataCalc.currentCurrency
        ].percents[dataCalc.currentPercent];
      currentCurrency = dataCalc.currentCurrency.toUpperCase();
      currentDescription = null;
      currentProduct = dataCalc.products[dataCalc.currentProduct].name;
      currentCqFormId = "loan_calc";
      currentCqButtonId = "loan_calc_button";
    }
  }

  if (type === "bonds") {
    if (dataType === "short") {
      currentCqFormId = "bond_short_form";
      currentCqButtonId = "bond_short_form_button";
    } else {
      dataCalc = calcBondsData;
      currentVal = dataCalc.currentVal;
      currentTerm =
        dataCalc.products[dataCalc.currentProduct][dataCalc.currentActiveBond]
          .termM;
      currentPercent =
        dataCalc.products[dataCalc.currentProduct][dataCalc.currentActiveBond]
          .rate;
      currentCurrency = dataCalc.currentCurrency;
      currentProduct = dataCalc.currentActiveBond;
      currentDescription =
        dataCalc.products[dataCalc.currentProduct][dataCalc.currentActiveBond]
          .name[langCurrent];
      currentCqFormId = "bond_calc";
      currentCqButtonId = "bond_calc_button";
    }
  }

  return [
    currentVal,
    currentTerm,
    currentPercent,
    currentCurrency,
    currentDescription,
    currentProduct,
    currentCqFormId,
    currentCqButtonId,
    currentCqPageId,
  ];
};
