import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateModalState } from '../../store/actions/base';
import styled from 'styled-components';

import { BREAKPOINTS } from '../../settings';

export const VideoModal = () => {
    const dispatch = useDispatch();
    const dataBase = useSelector((state) => state.base);
    const data = dataBase.modal.videoLink;

    const buttonClick = () => {
        updateModalState(dispatch, { type: null });
    };

    return (
        <StyledVideoModal>
            <div className="video-element">
                <div className="video-element__wrap" onClick={() => buttonClick()}>
                    <div className="video-element__content-outer">
                        <div className="video-element__content">
                            <div className="video-element__frame-wrap">
                                <iframe className="video-element__frame" allowFullScreen src={data}></iframe>
                            </div>
                        </div>
                        <button className="video-element__close-btn">×</button>
                    </div>
                </div>
            </div>
        </StyledVideoModal>
    );
};

const StyledVideoModal = styled.div`
    .video-element__wrap {
        position: absolute;
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &::before {
            content: '';
            height: 100%;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .video-element__content-outer {
        position: relative;
        padding: 0 1em;
        max-width: 100%;
        display: inline-block;
        vertical-align: middle;
    }

    .video-element__content {
        position: relative;
    }

    .video-element__frame-wrap {
        width: 40em;
        height: 0;
        overflow: hidden;
        padding-top: 56.25%;
        margin: 5em 0;

        @media (min-width: ${BREAKPOINTS.desktop_lg}px) {
            width: 50em;
        }
    }

    .video-element__frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.6);
        background: #000;
        border: 0;
    }

    .video-element__close-btn {
        font-size: 3em;
        position: absolute;
        right: 20px;
        top: 25px;
        color: rgba(255, 255, 255, 0.7);
        padding: 0;
        border: 0;
        cursor: pointer;
        background: 0 0;
    }
`;
