import { createGlobalStyle } from "styled-components";

import { BREAKPOINTS } from "../../../settings";

export const BlocksOrderStyles = createGlobalStyle`
  .layout__main {
    main {
      display: flex;
      flex-direction: column;

      > * {
        order: 4;
      }

      .main_page_heading_block_0,
      .about_block_1,
      .advantages_block_2 {
        order: 0;
      }

      .projects_block_3 {
        order: 3;

        @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
          order: 1;
        }
      }

      .about_block_4 {
        order: 2;
      }
      
      .products_block_5 {
        order: 1;

        @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
          order: 3;
        }
      }
    }
  }
`;
