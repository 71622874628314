import {
  checkEmailPattern,
  checkIsfilled,
  checkIsTrue,
} from "../helpers/inputValidator";

// TODO: переименовать, схема для формы заявки калькулятора займов
export const contactsShortModel = [
  {
    label: {
      ru: "Имя*",
      en: "Name*",
      kz: "Аты*",
    },
    type: "text",
    name: "name",
    col: 12,
    validators: [
      {
        id: "name-required",
        isValidFun: checkIsfilled,
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
    ],
  },
  {
    label: {
      ru: "Email*",
      en: "Email*",
      kz: "Email*",
    },
    type: "text",
    name: "email",
    col: 12,
    validators: [
      {
        id: "email-pattern",
        isValidFun: checkEmailPattern,
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
      {
        id: "email-required",
        isValidFun: checkIsfilled,
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
    ],
  },
  {
    label: {
      ru: "Телефон*",
      en: "Phone*",
      kz: "Телефон*",
    },
    type: "text",
    name: "phone",
    inputmode: "tel",
    placeholder: {
      ru: "+7 (__) ___-__-__",
      en: "+7 (__) ___-__-__",
      kz: "+7 (__) ___-__-__",
    },
    col: 12,
    validators: [
      {
        id: "phone-required",
        isValidFun: checkIsfilled,
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
    ],
  },
  {
    label: {
      ru: "<span>Отправляя заявку, я предоставляю согласие на <a href='/files/politiki-v-otnoshenii-pdn.pdf' target='_blank'>обработку персональных данных</a> и принимаю условия <a href='/files/politika-konfidentsialnosti.pdf' target='_blank'>Политики конфиденциальности</a></span>",
      en: "<span>By submitting, I consent to the <a href='/files/politiki-v-otnoshenii-pdn-en.pdf' target='_blank'>processing of my personal data</a> and accept the terms of the <a href='/files/politika-konfidentsialnosti.pdf' target='_blank'>Privacy Policy</a></span>",
      kz: "Өтінім беру арқылы мен жеке <a href='/files/politiki-v-otnoshenii-pdn-kz.pdf' target='_blank'>деректерді өңдеуге келісім</a> беремін және <a href='/files/politika-konfidentsialnosti.pdf' target='_blank'>Құпиялық саясатының</a> шарттарын қабылдаймын",
    },
    type: "checkbox",
    name: "check1",
    value: false,
    col: 12,
    validators: [
      {
        id: "terms-required",
        isValidFun: checkIsTrue,
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
    ],
  },
];
