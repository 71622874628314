// libraries
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// components
import { Col } from 'react-bootstrap';
import { ValidationAlert } from './ValidationAlert';
// constants
import { BREAKPOINTS, COLORS } from '../../settings';
// static
import iconInputFiles from '../../images/iconInputFiles.svg';
import checkIconFilesTrue from '../../images/check_green_icon.png';
import checkIconFilesFalse from '../../images/check_red_icon.png';

export const FileInput = ({
    name,
    noUpload,
    type,
    dependent,
    hidden,
    alert,
    col,
    col_lg,
    theme,
    className,
    setInputs,
    check,
    falseSize,
    falseMessage,
    callback,
    labelNew,
}) => {
    const dataBase = useSelector((state) => state.base);
    const langCurrent = dataBase.lang.current;
    // const withoutLabel = 'Вы не загрузили ни один файл';
    const withoutLabel = noUpload[langCurrent];

    const themeClass = theme ? ' ' + theme : '';
    const colClass = col ? ' cl-' + col : '';

    const [hoveredFileName, setHoveredFileName] = useState('');

    // const [inputLabel, setInputLabel] = useState(noUpload[langCurrent])

    const checkFile = (file) => {
        let fileFormat = [
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
            'application/pdf',
        ];

        if (!fileFormat.includes(file.type) && !(file.size > 4 * 1024 * 1024)) {
            callback(falseMessage[langCurrent]);
        } else if (fileFormat.includes(file.type) && file.size > 4 * 1024 * 1024) {
            callback(falseSize[langCurrent]);
        }
    };

    const getTruncatedFileName = (fileName) => {
        const maxLength = 17;
        const ellipsis = '...';
    
        if (fileName.length <= maxLength) {
            return fileName;
        }
    
        const truncatedName = fileName.substr(0, maxLength) + ellipsis;
        return truncatedName;
    };

    const changeVal = (e) => {
        const file = e.nativeEvent.srcElement.files[0];

        if (file) {
            const fileName = file.name;
            const truncatedFileName = getTruncatedFileName(fileName);
            callback(truncatedFileName);
            checkFile(file);
            setInputs(e, 'file', file.size);
            setHoveredFileName(fileName);
        } else if (!file) {
            callback(withoutLabel);
        }
    };

    return !hidden ? (
        <Col xs={col} lg={col_lg} className={className}>
            <StyledFilesInput>
                <div
                    className={
                        dependent ? 'dependent' + ' input' + colClass + themeClass : 'input' + colClass + themeClass
                    }
                >
                    <label
                        className={check}
                        htmlFor={name}
                        dangerouslySetInnerHTML={{ __html: labelNew }}
                        title={hoveredFileName}
                    />
                    <input
                        required
                        id={name}
                        name={name}
                        type={type}
                        className={alert ? 'error' : 'message_file'}
                        onChange={changeVal}
                    />
                    <ValidationAlert content={alert} theme={theme} />
                </div>
            </StyledFilesInput>
        </Col>
    ) : (
        ''
    );
};

const StyledFilesInput = styled.div`
    height: 100%;
    .input {
        position: relative;
        margin: 0 0 10px;
        height: 100%;

        label {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-end;
            justify-content: center;
            text-align: center;
            margin: 0;
            padding: 0 20px 27px;
            width: 100%;
            height: 100%;
            color: ${COLORS.text_secondary};
            border: 2px dashed rgba(0, 0, 0, 0.8);
            background-color: #f9f9f9;
            font-size: 15px;
            cursor: pointer;
            word-wrap: break-word;
            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                font-size: 16px;
                padding: 80px 60px;
            }
            &._false-file {
                border: 2px dashed #e14c4c !important;
            }
            &._true {
                &:after {
                    content: '';
                    position: absolute;
                    top: calc(50% - 10px);
                    right: 20px;
                    width: 20px;
                    height: 20px;
                    background: url(${checkIconFilesTrue}) center/cover no-repeat;
                }
            }
            &._false {
                &:after {
                    content: '';
                    position: absolute;
                    top: calc(50% - 10px);
                    right: 20px;
                    width: 20px;
                    height: 20px;
                    background: url(${checkIconFilesFalse}) center/cover no-repeat;
                }
            }
            &:before {
                content: '';
                position: absolute;
                top: calc(50% - 60px);
                left: calc(50% - 37px);
                width: 74px;
                height: 74px;
                background: url(${iconInputFiles}) center/cover no-repeat;
            }
        }
        &.textarea {
            &::before {
                @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
                    bottom: 1px;
                    width: calc(100% - 40px);
                    height: 11px;
                    background: #ffffff;
                    border-top: 1px solid hsla(213, 65%, 35%, 0.1);
                }
            }
            &::after {
                content: '';
                position: absolute;
                top: 1px;
                right: 20px;
                z-index: 1;
                width: calc(100% - 40px);
                height: 33px;
                background: #ffffff;
            }
            label {
                @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
                    top: 20px;
                }
            }
        }
        input,
        textarea {
            display: none;
            width: 100%;
            padding: 25px 0 15px;
            margin: -25px 0 0;
            background: #ffffff;
            border: none;
            border-bottom: 1px solid ${COLORS.text_secondary};
            border-radius: 2px;
            color: ${COLORS.text_secondary};
            font-size: 20px;
            line-height: 1;
            transition: 0.3s;
            @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
                margin: -30px 0 0;
                font-size: 30px;
            }
            &:focus {
                border-color: ${COLORS.text_accent};
                box-shadow: none;
                outline: none;
            }
            &.error {
                border-color: #e14c4c;
                &:focus {
                    box-shadow: none;
                }
            }
            .readonly-field & {
                border-color: mediumspringgreen;
            }
        }
        textarea {
            resize: none;
            height: 140px;
            padding-top: 33px;
        }
    }
    .blue {
        input,
        textarea {
            background: hsl(213, 82%, 96%);
            margin: 0 0 40px;
        }
    }
    .dependent {
        margin: -10px 0 10px;
        &.textarea {
            &::before {
                @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
                    background: hsl(216, 29%, 97%);
                }
            }
            &::after {
                background: hsl(216, 29%, 97%);
            }
        }
        input,
        textarea {
            background: hsl(216, 29%, 97%);
            border: none;
            border-radius: 0;
        }
        span.error {
            background: transparent;
        }
    }

    .cl-4 {
        &::before {
            @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
                width: calc(35% - 20px);
            }
        }
        input {
            @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
                padding-left: 65%;
            }
        }
    }
`;
