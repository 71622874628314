// libraries
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
// helpers
import { translator } from '../../helpers/lang';
import { validateEmail } from '../../helpers/base';
import { getCookie } from '../../helpers/cookies';
// constants
import { API, BREAKPOINTS } from '../../settings';
// static
import nav_right_svg from '../../images/slider_arrow_inactive.svg';

export const SubscribeBlock = () => {
    const dataBase = useSelector((state) => state.base);
    const langCurrent = dataBase.lang.current;

    const [ipAddress, setIpAddress] = useState(false);

    useEffect(() => {
        (async () => {
            await fetch('https://api.ipify.org/?format=json', {
                method: 'GET',
            })
                .then((response) => response.json())
                .then((data) => {
                    setIpAddress(data.ip);
                })
                .catch((err) => {
                    console.error(err);
                });
        })();
    }, []);

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isMailSended, mailSended] = useState(false);
    const [sendedText, setSendedText] = useState('');

    const subscribeFormWrapper = React.createRef();
    const mainForm = useRef(null);

    const senderUrl = API.SEND_SUBSCRIBE_FORM;
    const checkForm = (event) => {
        event.preventDefault();

        let element = event.target;
        let isAllChecked = true;

        if (validateEmail(element.value)) {
            element.parentNode.dataset.checked = true;
        } else {
            element.parentNode.dataset.checked = false;
            isAllChecked = false;
        }

        setIsButtonDisabled(!isAllChecked);
    };

    const getRecaptchaToken = (event) => {
        event.preventDefault();
        typeof window !== 'undefined' &&
            window.grecaptcha.ready(async () => {
                const response = await window.grecaptcha.execute('6LdlFF0gAAAAAGN7qZCPcfABsf6NJSGADIIeGsqS', {
                    action: 'submit',
                });
                const token = await response;
                sendForm(token);
            });
    };

    const sendForm = (token) => {
        let formData = new FormData(mainForm.current);
        formData.append('recaptchaToken', token);

        fetch(senderUrl, {
            method: 'post',
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                if (data && data.status) {
                    switch (data.type) {
                        case 'notprod':
                            setSendedText(
                                translator(langCurrent, {
                                    ru: 'notprod На Ваш email выслана ссылка для подтверждения.',
                                    en: 'notprod A confirmation link has been sent to your email.',
                                    kz: 'notprod Сіздің email мекенжайыңызға растауға арналған сілтеме жіберілді.',
                                })
                            );
                            break;
                        case 'done':
                            setSendedText(
                                translator(langCurrent, {
                                    ru: 'На Ваш email выслана ссылка для подтверждения.',
                                    en: 'A confirmation link has been sent to your email.',
                                    kz: 'Сіздің email мекенжайыңызға растауға арналған сілтеме жіберілді.',
                                })
                            );
                            break;
                        case 'emailexist':
                            setSendedText(
                                translator(langCurrent, {
                                    ru: 'Ошибка!\n' + 'Этот email уже был внесен в базу подписчиков!',
                                    en: 'Error!\n' + 'This email has already been added to the subscriber database!',
                                    kz: 'Қате!\n' + 'Бұл электрондық пошта жазылушылардың деректер базасына қосылды!',
                                })
                            );
                            break;
                        default:
                            setSendedText(
                                translator(langCurrent, {
                                    ru: 'Извините, сервис временно недоступен.',
                                    en: 'Sorry, the service is temporarily unavailable.',
                                    kz: 'ешіріңіз, қызмет уақытша қол жетімді емес.',
                                })
                            );
                            break;
                    }
                    mailSended(true);
                } else {
                    setSendedText(
                        translator(langCurrent, {
                            ru: 'Извините, сервис временно недоступен.',
                            en: 'Sorry, the service is temporarily unavailable.',
                            kz: 'Кешіріңіз, қызмет уақытша қол жетімді емес.',
                        })
                    );
                    mailSended(true);
                }
            })
            .catch((error) => {
                console.error('Ошибка:', error);
                setSendedText(
                    translator(langCurrent, {
                        ru: 'Извините, сервис временно недоступен.',
                        en: 'Sorry, the service is temporarily unavailable.',
                        kz: 'Кешіріңіз, қызмет уақытша қол жетімді емес.',
                    })
                );
                mailSended(true);
            });

        subscribeFormWrapper.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };

    const partner = typeof window !== `undefined` && window.document && (getCookie('partner') || '-');

    return (
        <StyledSubscribeBlock className="block-container" ref={subscribeFormWrapper}>
            <div className="subscribe_wrapper">
                <h2
                    className="title"
                    dangerouslySetInnerHTML={{
                        __html: translator(langCurrent, {
                            ru: 'Получайте еженедельные обновления от нашей команды',
                            en: 'Receive weekly updates from our team',
                            kz: 'Біз командамыздан апта сайын жаңа ақпаратты алыңыз',
                        }),
                    }}
                />
                {isMailSended ? (
                    <div className="form_alert">
                        <div
                            className="subtitle"
                            dangerouslySetInnerHTML={{
                                __html: sendedText,
                            }}
                        />
                    </div>
                ) : (
                    <form ref={mainForm} onChange={checkForm} onSubmit={getRecaptchaToken}>
                        <input type="hidden" name="ip" value={ipAddress} />

                        <input type="hidden" name="partner" value={partner} />

                        <input type="hidden" name="lang" value={langCurrent} />
                        <input
                            type="email"
                            name="email"
                            placeholder={translator(langCurrent, {
                                ru: 'Введите почту',
                                en: 'Enter mail',
                                kz: 'Поштаны енгізіңіз',
                            })}
                        />
                        <button type="submit" disabled={isButtonDisabled}>
                            <img src={nav_right_svg} alt="nav right" />
                        </button>
                    </form>
                )}
            </div>
        </StyledSubscribeBlock>
    );
};

const StyledSubscribeBlock = styled.div`
    width: 100%;
    height: auto;
    padding: 30px 0;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        padding: 30px 20px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
        padding: 60px 70px;
    }

    .subscribe_wrapper {
        background: #2d3b4d;
        padding: 30px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            padding: 60px 40px;
            border-radius: 7px;
        }
        .title {
            width: 100%;
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            color: #ffffff;
            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                width: 48%;
                font-size: 30px;
                line-height: 45px;
            }
        }
        form {
            width: 100%;
            height: 65px;
            border-bottom: 1px solid #b6bec0;
            position: relative;
            &[data-checked='true'] {
                border-bottom: 1px solid mediumspringgreen;
            }
            &[data-checked='false'] {
                border-bottom: 1px solid red;
            }
            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                width: 48%;
                height: 85px;
                font-size: 30px;
                line-height: 45px;
            }
            input {
                height: 100%;
                width: 100%;
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 20px;
                line-height: 30px;
                color: #b6bec0;
                z-index: 1;
                padding-right: 30px;
                @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                    font-size: 30px;
                    line-height: 45px;
                }
            }
            button {
                position: absolute;
                z-index: 2;
                width: 30px;
                height: 100%;
                right: 0;
                top: 0;
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                &:disabled {
                    opacity: 0.2;
                }
                img {
                    width: 14px;
                    height: 14px;
                    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                        width: 28px;
                        height: 28px;
                    }
                }
            }
        }
        .form_alert {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                width: 48%;
                height: auto;
                font-size: 30px;
                line-height: 45px;
            }
            .title {
                font-size: 28px;
                width: 100%;
                height: auto;
            }
            .subtitle {
                width: 100%;
                height: auto;
                font-size: 22px;
                line-height: 30px;
                color: #fff;
            }
        }
    }
`;
