import React from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";

export const FieldsetInput = ({ label, hidden, col, theme, children }) => {
  return !hidden ? (
    <Col md={col}>
      <StyledFieldset theme={theme}>
        <p className="fieldset-title">{label}</p>
        {children}
      </StyledFieldset>
    </Col>
  ) : (
    ""
  );
};

const StyledFieldset = styled.div`
  margin: 10px 0 0;
  .fieldset-title {
    margin: ${props => (props.theme.marginLarge ? "0 0 20px" : "0 0 10px")};
    font-weight: 700;
  }
`;
