import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { COLORS } from "../../settings";
import { translator } from "../../helpers/lang";
import { updateSidebarNavState } from "../../store/actions/base";

export const Overlay = () => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  const ClickHandler = () => {
    updateSidebarNavState(dispatch);
  };

  return (
    <StyledOverlay
      onClick={ClickHandler}
      isOpen={dataBase.sidebar.navOpen}
      aria-label={translator(langCurrent, {
        ru: "Закрыть",
        en: "Close",
        kz: "Закрыть",
      })}
    />
  );
};

const StyledOverlay = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  opacity: ${props => (props.isOpen ? ".4" : "0")};
  display: block;
  width: 100%;
  background: ${COLORS.bg_dark};
  padding: 0;
  border: none;
  pointer-events: ${props => (props.isOpen ? "auto" : "none")};
  transition: opacity 0.3s ease-out;
`;
