import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { API, FORM, BREAKPOINTS, COLORS } from '../../settings';
import { getCookie } from '../../helpers/cookies';
import { renderInput } from '../../helpers/renderInput';
// import { getDataToSend } from "../../helpers/getDataToSend";
import { translator } from '../../helpers/lang';
import { useForm } from '../../hooks/use-form';
import { updateModalState } from '../../store/actions/base';
import icoNavClose from '../../images/nav_close_black.svg';
import icoNavCloseMobile from '../../images/nav_close_mobile_black.svg';
import { Loader } from '../Loader';
import { FormButton } from '../FormButton';
import { calcTypeData } from '../../helpers/calcTypeData';

const crmLeadConfig = {
    fields: {
        Name: '#name',
        Email: '#email',
        MobilePhone: '#phone',
    },
    landingId: 'b9d11f45-5eb6-439f-8d5e-a4211dfed9e0',
    serviceUrl:
        'https://kjhgyugfou.idfinance.com:44344/0/ServiceModel/GeneratedObjectWebFormService.svc/SaveWebFormObjectData',
    redirectUrl: '',
};

export const ContactShortForm = ({ type, pageId }) => {
    const dispatch = useDispatch();
    const dataBase = useSelector((state) => state.base);
    const langCurrent = dataBase.lang.current;
    const [loading, setLoading] = useState(false);
    const [pageScroll, setPageScroll] = useState(false);
    const lastSessionReferrer = dataBase.oldUserData?.data?.props?.$last_session_referrer; //Последняя сессия

    // const dataUser = {
    //   cqUser:
    //     typeof window !== `undefined` &&
    //     window.document &&
    //     getCookie("carrotquest_uid"),
    // };

    const [
        currentVal,
        currentTerm,
        currentPercent,
        currentCurrency,
        currentDescription,
        currentProduct,
        currentCqFormId,
        currentCqButtonId,
        currentCqPageId,
    ] = calcTypeData(dataBase.modal.productType, 'short', null, null, langCurrent);

    const buttonClick = () => {
        updateModalState(dispatch, { type: null });
        setMessage({
            type: null,
            text: null,
        });
    };

    const getRecaptchaToken = () => {
        typeof window !== 'undefined' &&
            window.grecaptcha.ready(async () => {
                const response = await window.grecaptcha.execute('6LdlFF0gAAAAAGN7qZCPcfABsf6NJSGADIIeGsqS', {
                    action: 'submit',
                });
                const token = await response;

                let data = {
                    cqUser: typeof window !== `undefined` && window.document && getCookie('carrotquest_uid'),
                    cqFormId: currentCqFormId,
                    cqButtonId: currentCqButtonId,
                    currentCqPageId: currentCqPageId,
                    lastSessionReferrer: lastSessionReferrer ? lastSessionReferrer : '',
                    partner: typeof window !== `undefined` && window.document && (getCookie('partner') || '-'),
                    utms: typeof window !== `undefined` && window.document && (getCookie('utms') || ''),
                    wmid: typeof window !== `undefined` && window.document && (getCookie('wmid') || ''),
                    clickid: typeof window !== `undefined` && window.document && (getCookie('clickid') || ''),
                    lang: dataBase.lang.current.toUpperCase(),
                    ip: dataBase.ip,
                    url: typeof window !== `undefined` && window.location.href,
                    type: dataBase.modal.productType,
                };

                let optimisedInputs = {};
                inputs.forEach((input) => {
                    if (input.name === 'check1') {
                        optimisedInputs.check1 = input.value ? 'on' : 'off';
                    } else {
                        optimisedInputs[input.name] = input.value;
                    }
                });

                const sendData = { ...optimisedInputs, ...data, ...{ recaptchaToken: token } };

                sendForm(sendData);
            });
    };

    const sendForm = (sendData) => {
        setLoading(true);

        fetch(API.SEND_SHORT_FORM, {
            ...API.HEADERS.POST,
            body: JSON.stringify(sendData),
        })
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                if (data && data.status) {
                    window.landing && window.landing.createObjectFromLanding(crmLeadConfig);

                    if (dataBase.modal.productType === 'loans') {
                        if (window.ym) {
                            window.ym(57564463, 'reachGoal', 'loan_calc_leadform_in_work');
                        }
                        if (window.dataLayer) {
                            window.dataLayer.push({ event: 'loan_calc_leadform_in_work' });
                        }
                    }

                    if (dataBase.modal.productType === 'bonds') {
                        if (window.ym) {
                            window.ym(57564463, 'reachGoal', 'bond_calc_leadform_in_work');
                        }
                        if (window.dataLayer) {
                            window.dataLayer.push({ event: 'bond_calc_leadform_in_work' });
                        }
                    }

                    setMessage({
                        type: 'success',
                        text: FORM.FORM_SUCCESS[langCurrent],
                    });
                    resetForm();
                    setPageScroll(false);
                    // getDataToSend(
                    //   API.GET_USER_DATA,
                    //   API.HEADERS.POST,
                    //   dispatch,
                    //   dataUser
                    // );
                } else {
                    setMessage({
                        type: 'error',
                        text: FORM.FORM_ERROR[langCurrent],
                    });
                }
            });
    };

    const [inputs, setInputs, setSubmit, resetForm, message, setMessage] = useForm(
        type,
        getRecaptchaToken,
        null,
        setPageScroll
    );

    useEffect(() => {
        if (pageScroll) {
            scrollTo(pageScroll);
        }
    }, [pageScroll]);

    return (
        <StyledContactForm message={message}>
            <div id="formtop2" className="heading">
                <h2 id="formtop1" className="title h2">
                    {translator(langCurrent, {
                        ru: 'Ответим на вопросы',
                        en: 'Do you have any questions?',
                        kz: 'Сұрақтарға жауап береміз',
                    })}
                </h2>
            </div>
            {message.type && message.type === 'success' && (
                <div className={`form-message${message.type ? ' ' + message.type : ''}`}>{message.text}</div>
            )}
            <div className="form">
                {loading && <Loader />}
                <form>
                    <Row>{inputs.map((input) => renderInput(input, setInputs))}</Row>
                    <FormButton
                        title={translator(langCurrent, {
                            ru: 'Отправить',
                            en: 'Send message',
                            kz: 'Жіберу',
                        })}
                        eventHandler={setSubmit}
                    />
                </form>
            </div>
            <button className="close" onClick={() => buttonClick()} />
        </StyledContactForm>
    );
};

const StyledContactForm = styled.div`
    pointer-events: auto;
    position: relative;
    background: #ffffff;
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        padding: 40px;
    }
    @media (min-width: 840px) {
        max-width: 800px;
        margin: 20px auto;
    }
    .heading {
        padding: 0 40px 0 0;
        @media (min-width: ${BREAKPOINTS.tablet}px) {
            padding: 0;
        }
    }
    .title {
        color: ${COLORS.text_accent};
    }
    .form {
        margin: 40px 0 0;
    }
    form {
        display: block;
    }
    .form-message {
        display: ${(props) => (props.message.type ? 'block' : 'none')};
        padding: 15px;
        margin: 40px 0 0;
        border: 2px solid transparent;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        color: #9edc15;
        &.success {
            color: #0b70d6;
            border-color: #0b70d6;
        }
        &.error {
            color: #ff3f3f;
            border-color: #ff3f3f;
        }
    }
    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;
        display: flex;
        align-items: center;
        background: transparent;
        padding: 0;
        border: none;
        color: ${COLORS.text_inverted};
        font-size: 16px;
        line-height: 25px;

        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            top: 10px;
            right: 10px;
        }

        &:hover,
        &:focus {
            &::before {
                transform: rotate(180deg);
            }
        }

        &::before {
            content: '';

            width: 40px;
            height: 40px;
            background: url(${icoNavCloseMobile}) center/contain no-repeat;

            transform: rotate(0);
            transform-origin: center;
            transition: transform 0.3s ease-in-out;

            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                background-image: url(${icoNavClose});

                transform-origin: 18px 22px;
            }
        }
    }
`;
