import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "gatsby";
import { COLORS } from "../../../settings";
import { hexToRgb } from "../../../helpers/base";
import { langLinkClass } from "../../../helpers/lang";

export const LangSwitcherLink = ({ lang }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  const linkProps = {
    className: langLinkClass(langCurrent, lang.langCode),
  };

  return langCurrent ? (
    <StyledLangLink className="lang-list_item text-bold">
      {lang.link ? (
        <Link {...linkProps} to={lang.link.replace("/wp/", "/")}>
          {lang.name}
        </Link>
      ) : (
        <a {...linkProps}>{lang.name}</a>
      )}
    </StyledLangLink>
  ) : null;
};

const StyledLangLink = styled.li`
  & + & {
    margin: 0 0 0 25px;
  }

  a {
    color: rgba(${hexToRgb(COLORS.text_inverted).toString()}, 0.5);
    text-decoration: none;
    transition: color 0.15s ease-out;

    &:hover,
    &:focus {
      color: ${COLORS.text_accent};
    }

    &.lang-list_link__active {
      color: ${COLORS.text_accent};
    }
  }
`;
