// libraries
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from 'styled-components';
// actions
import { updateModalState } from '../../store/actions/base';
// components
import { Row } from 'react-bootstrap';
import { Loader } from '../Loader';
import { FormButton } from '../FormButton';
import { API, FORM, BREAKPOINTS, COLORS } from '../../settings';
// helpers
import { renderInput } from '../../helpers/renderInput';
import { translator } from '../../helpers/lang';
// hooks
import { useForm } from '../../hooks/use-form';
// static
import icoNavClose from '../../images/nav_close_black.svg';
import icoNavCloseMobile from '../../images/nav_close_mobile_black.svg';

const checkLang = {
    ru: 'Загрузите файл',
    en: 'Upload the file',
    kz: 'Файлды жүктеңіз',
};
const checkUploadLang = {
    ru: 'Нажмите, чтобы загрузить файл',
    en: 'Click to download the file',
    kz: 'Файлды жүктеу үшін нұқыңыз',
};

export const CareerCallbackFormBlock = ({ type }) => {
    const dispatch = useDispatch();
    const dataBase = useSelector((state) => state.base);
    const langCurrent = dataBase.lang.current;
    const [loading, setLoading] = useState(false);
    const [pageScroll, setPageScroll] = useState(false);
    const [inputLabel, setInputLabel] = useState(checkLang[langCurrent]);

    const inputEl = useRef(null);
    const buttonClick = () => {
        updateModalState(dispatch, { type: null });
        setMessage({
            type: null,
            text: null,
        });
    };

    const getRecaptchaToken = () => {
        typeof window !== 'undefined' &&
            window.grecaptcha.ready(async () => {
                const response = await window.grecaptcha.execute('6LdlFF0gAAAAAGN7qZCPcfABsf6NJSGADIIeGsqS', {
                    action: 'submit',
                });
                const token = await response;

                sendForm(token);
            });
    };

    const sendForm = (token) => {
        setLoading(true);

        let formData = new FormData(inputEl.current);
        formData.append('recaptchaToken', token);

        fetch(API.SEND_REQUEST_VACANCY, {
            ...API.HEADERS.POST,
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                if (data && data.status) {
                    setMessage({
                        type: 'success',
                        text: FORM.FORM_SUCCESS[langCurrent],
                    });
                    resetForm();
                    setInputLabel(checkUploadLang[langCurrent]);
                    inputEl.current.message_files.value = '';
                    setPageScroll(false);
                } else {
                    setMessage({
                        type: 'error',
                        text: FORM.FORM_ERROR[langCurrent],
                    });
                }
            });
    };

    const [inputs, setInputs, setSubmit, resetForm, message, setMessage] = useForm(
        type,
        getRecaptchaToken,
        null,
        setPageScroll
    );

    useEffect(() => {
        if (pageScroll) {
            scrollTo(pageScroll);
        }
    }, [pageScroll]);

    return (
        <StyledCareerCallbackFormBlock message={message}>
            <div id="formtop2" className="heading">
                <h2 id="formtop1" className="title h2">
                    {translator(langCurrent, {
                        ru: 'Оставить заявку',
                        en: 'Submit an application',
                        kz: 'Өтінім қалдыру',
                    })}
                </h2>
                <p className="subtitle">
                    {translator(langCurrent, {
                        ru: 'Заполните заявку, и мы свяжемся с вами в ближайшее время',
                        en: 'Fill in the application form and we will contact you as soon as possible',
                        kz: 'Өтінімді қалдырыңыз және біз сізбен жақын арада хабарласамыз',
                    })}
                </p>
            </div>
            {message.type && message.type === 'success' && (
                <div className={`form-message${message.type ? ' ' + message.type : ''}`}>{message.text}</div>
            )}
            <div className="form">
                {loading && <Loader />}
                <form ref={inputEl}>
                    <Row>{inputs.map((input) => renderInput(input, setInputs, null, setInputLabel, inputLabel))}</Row>
                    <FormButton
                        title={translator(langCurrent, {
                            ru: 'Отправить',
                            en: 'Send message',
                            kz: 'Жіберу',
                        })}
                        eventHandler={setSubmit}
                    />
                </form>
            </div>
            <button className="close" onClick={() => buttonClick()} />
        </StyledCareerCallbackFormBlock>
    );
};

const StyledCareerCallbackFormBlock = styled.div`
    pointer-events: auto;
    position: relative;
    background: #ffffff;
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        padding: 40px;
    }
    @media (min-width: 840px) {
        max-width: 800px;
        margin: 20px auto;
    }
    .heading {
        padding: 0 40px 0 0;
        @media (min-width: ${BREAKPOINTS.tablet}px) {
            padding: 0;
        }
    }
    .title {
        color: ${COLORS.text_accent};
    }
    .form {
        margin: 40px 0 0;
    }
    form {
        display: block;
    }
    .form-message {
        display: ${(props) => (props.message.type ? 'block' : 'none')};
        padding: 15px;
        margin: 40px 0 0;
        border: 2px solid transparent;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        color: #9edc15;
        &.success {
            color: #0b70d6;
            border-color: #0b70d6;
        }
        &.error {
            color: #ff3f3f;
            border-color: #ff3f3f;
        }
    }
    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;
        display: flex;
        align-items: center;
        background: transparent;
        padding: 0;
        border: none;
        color: ${COLORS.text_inverted};
        font-size: 16px;
        line-height: 25px;

        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            top: 10px;
            right: 10px;
        }

        &:hover,
        &:focus {
            &::before {
                transform: rotate(180deg);
            }
        }

        &::before {
            content: '';

            width: 40px;
            height: 40px;
            background: url(${icoNavCloseMobile}) center/contain no-repeat;

            transform: rotate(0);
            transform-origin: center;
            transition: transform 0.3s ease-in-out;

            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                background-image: url(${icoNavClose});

                transform-origin: 18px 22px;
            }
        }
    }
`;
