import {
    checkEmailPattern,
    checkIsfilled,
    checkIsTrue,
    checkFormatFiles,
    checkSizeFiles,
} from '../helpers/inputValidator';

export const vacanciesModel = [
    {
        placeholder: {
            ru: 'Имя*',
            en: 'Name*',
            kz: 'Аты*',
        },
        type: 'text',
        name: 'first_name',
        className: 'firstName',
        validators: [
            {
                id: 'name-required',
                isValidFun: checkIsfilled,
                alert: {
                    ru: 'empty',
                    en: 'empty',
                    kz: 'empty',
                },
            },
        ],
    },
    {
        placeholder: {
            ru: 'Фамилия*',
            en: 'Last Name*',
            kz: 'Аты*',
        },
        type: 'text',
        name: 'last_name',
        className: 'lastName',
        validators: [
            {
                id: 'name-required',
                isValidFun: checkIsfilled,
                alert: {
                    ru: 'empty',
                    en: 'empty',
                    kz: 'empty',
                },
            },
        ],
    },
    {
        placeholder: {
            ru: 'Email*',
            en: 'Email*',
            kz: 'Email*',
        },
        type: 'text',
        name: 'email',
        className: 'email',
        validators: [
            {
                id: 'email-pattern',
                isValidFun: checkEmailPattern,
                alert: {
                    ru: 'empty',
                    en: 'empty',
                    kz: 'empty',
                },
            },
            {
                id: 'email-required',
                isValidFun: checkIsfilled,
                alert: {
                    ru: 'empty',
                    en: 'empty',
                    kz: 'empty',
                },
            },
        ],
    },
    {
        placeholder: {
            ru: 'Текст сообщения*',
            en: 'Message*',
            kz: 'Xабар*',
        },
        className: 'messageInput messageText',
        type: 'textarea',
        name: 'message_text',
        validators: [
            {
                id: 'name-required',
                isValidFun: checkIsfilled,
                alert: {
                    ru: 'empty',
                    en: 'empty',
                    kz: 'empty',
                },
            },
        ],
    },

    {
        noUpload: {
            ru: 'Вы не загрузили ни один файл',
            en: "You haven't uploaded any files",
            kz: 'Сіз ешқандай файл жүктеген жоқсыз',
        },
        falseMessage: {
            ru: 'Можно прикрепить только файл pdf/docx/doc до 4МБ.',
            en: 'You can only attach a pdf / docx / doc file up to 4MB.',
            kz: 'Сіз тек pdf / docx / doc файлын 4MB дейін қоса аласыз.',
        },
        falseSize: {
            ru: 'Файл должен быть не более 4 МБ.',
            en: 'File size should be less than 4MB',
            kz: 'Файл 4 Мбайттан аспауы керек.',
        },
        type: 'file',
        name: 'message_files',
        className: 'fileInput file',
        check: '',
        validators: [
            {
                id: 'files-required',
                isValidFun: checkFormatFiles,
                alert: {
                    ru: 'Неверный формат (допустимые форматы: pdf / docx / doc, размер файла до 4МБ.)',
                    en: 'Invalid format (valid formats: pdf / docx / doc, file size up to 4MB.)',
                    kz: 'Жарамсыз формат (жарамды форматтар: pdf / docx / doc, файл өлшемі 4МБ дейін.)',
                },
            },
            {
                id: 'size-required',
                isValidFun: checkSizeFiles,
                alert: {
                    ru: 'Файл должен быть не более 4 МБ.',
                    en: 'You can attach only  a pdf / docx / doc file up to 4MB',
                    kz: 'Файл 4 Мбайттан аспауы керек.',
                },
            },
        ],
    },
    {
        label: {
            ru: 'Я даю согласие на&nbsp;<a href="/files/politiki-v-otnoshenii-pdn.pdf" target="_blank">обработку персональных данных</a>',
            en: 'By submitting, I consent to the&nbsp;<a href="/files/politiki-v-otnoshenii-pdn-en.pdf" target="_blank">processing of my personal data</a>',
            kz: 'Өтінімді қалдыра отырып, дербес&nbsp;<a href="/files/politiki-v-otnoshenii-pdn-kz.pdf" target="_blank">деректерді өңдеуге келісім</a> беремін',
        },
        type: 'checkbox',
        name: 'check1',
        customColClass: 'checkbox',
        value: false,
        validators: [
            {
                id: 'terms-required',
                isValidFun: checkIsTrue,
                alert: {
                    ru: 'empty',
                    en: 'empty',
                    kz: 'empty',
                },
            },
        ],
    },
];
