import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Col } from "react-bootstrap";

export const CityInput = ({ name, hide, col, col_lg, className }) => {
  const dataBase = useSelector(state => state.base);

  return (
    <Col xs={col} lg={col_lg} className={className}>
      <StyledCityInput>
        <input
          readOnly
          hidden={hide || null}
          id={name}
          name={name}
          type="text"
          value={dataBase.modal.modalData}
        />
      </StyledCityInput>
    </Col>
  );
};

const StyledCityInput = styled.div`
  width: 100%;
  height: 50px;
  border: 2px solid red;
  background-color: #aeaeae;
  visibility: hidden;
  opacity: 0;
  display: none;
`;
