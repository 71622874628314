import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import { COLORS } from '../../settings';
import { ValidationAlert } from './ValidationAlert';

const clickHandler = (field, product) => {};

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

export const TextInput = ({
    name,
    label,
    type,
    value,
    inputmode,
    dependent,
    hidden,
    placeholder,
    alert,
    readonly,
    setInputs,
    col,
    col_lg,
    theme,
    productId,
    className,
    masked,
}) => {
    const dataBase = useSelector((state) => state.base);

    const langCurrent = dataBase.lang.current;

    const inputRef = useRef(null);
    const setVal = () => {
        if (!inputRef.current.value) {
            inputRef.current.value = '+7 (';
            setTimeout(function () {
                inputRef.current.selectionStart = inputRef.current.selectionEnd = 10000;
            }, 100);
        }
    };

    const themeClass = theme ? ' ' + theme : '';
    const colClass = col ? ' cl-' + col : '';

    return !hidden ? (
        <Col xs={col} lg={col_lg} className={className}>
            <StyledInput>
                <div
                    className={
                        dependent ? 'dependent' + ' input' + colClass + themeClass : 'input' + colClass + themeClass
                    }
                >
                    {label && <label htmlFor={name}>{label[langCurrent]}</label>}
                    {masked ? (
                        <ConditionalWrapper
                            condition={name === 'income'}
                            wrapper={(children) => <div className={'form-control-income-wrapper'}>{children}</div>}
                        >
                            <NumberFormat
                                format={masked.format}
                                prefix={masked.prefix}
                                allowEmptyFormatting={masked.allowEmptyFormatting}
                                mask={masked.mask}
                                displayType={'input'}
                                id={name}
                                name={name}
                                type={type}
                                value={value || ''}
                                placeholder={placeholder && placeholder[langCurrent] || ''}
                                inputMode={inputmode || 'text'}
                                readOnly={readonly || false}
                                className={alert ? 'error' : ''}
                                onChange={setInputs}
                                getInputRef={inputRef}
                                onFocus={setVal}
                                onClick={() => clickHandler(name, productId)}
                            />
                        </ConditionalWrapper>
                    ) : (
                        <input
                            id={name}
                            name={name}
                            type={type}
                            value={value || ''}
                            inputMode={inputmode || 'text'}
                            readOnly={readonly || false}
                            placeholder={placeholder && placeholder[langCurrent] || ''}
                            className={alert ? 'error' : ''}
                            onChange={setInputs}
                            onClick={() => clickHandler(name, productId)}
                        />
                    )}
                    <ValidationAlert content={alert} theme={theme} />
                </div>
            </StyledInput>
        </Col>
    ) : (
        ''
    );
};

const StyledInput = styled.div`
    .input {
        position: relative;
        margin: 0 0 10px;
        label {
            margin: 0;
            width: 160px;
            color: ${COLORS.text_secondary};
            font-size: 15px;
            @media (min-width: 1280px) {
                font-size: 16px;
            }
        }
        &.textarea {
            &::before {
                @media (min-width: 1280px) {
                    bottom: 1px;
                    width: calc(100% - 40px);
                    height: 11px;
                    background: #ffffff;
                    border-top: 1px solid hsla(213, 65%, 35%, 0.1);
                }
            }
            &::after {
                content: '';
                position: absolute;
                top: 1px;
                right: 20px;
                z-index: 1;
                width: calc(100% - 40px);
                height: 33px;
                background: #ffffff;
            }
            label {
                @media (min-width: 1280px) {
                    top: 20px;
                }
            }
        }
        input,
        textarea {
            display: block;
            width: 100%;
            padding: 25px 0 15px;
            margin: -25px 0 0;
            background: #ffffff;
            border: none;
            border-bottom: 1px solid ${COLORS.text_secondary};
            border-radius: 2px;
            color: ${COLORS.text_secondary};
            font-size: 20px;
            line-height: 1;
            transition: 0.3s;
            @media (min-width: 1280px) {
                margin: -30px 0 0;
                font-size: 30px;
            }
            &:focus {
                border-color: ${COLORS.text_accent};
                box-shadow: none;
                outline: none;
            }
            &.error {
                border-color: #e14c4c;
                &:focus {
                    box-shadow: none;
                }
            }
            .readonly-field & {
                border-color: mediumspringgreen;
            }
        }
        textarea {
            resize: none;
            height: 140px;
            padding-top: 33px;
        }
    }
    .blue {
        input,
        textarea {
            background: hsl(213, 82%, 96%);
            margin: 0 0 40px;
        }
    }
    .dependent {
        margin: -10px 0 10px;
        &.textarea {
            &::before {
                @media (min-width: 1280px) {
                    background: hsl(216, 29%, 97%);
                }
            }
            &::after {
                background: hsl(216, 29%, 97%);
            }
        }
        input,
        textarea {
            background: hsl(216, 29%, 97%);
            border: none;
            border-radius: 0;
        }
        span.error {
            background: transparent;
        }
    }

    .cl-4 {
        &::before {
            @media (min-width: 1280px) {
                width: calc(35% - 20px);
            }
        }
        input {
            @media (min-width: 1280px) {
                padding-left: 65%;
            }
        }
    }
`;
