// libraries
import React from "react";
import styled from "styled-components";

export const ValidationAlert = ({ content, theme }) => {
  const activeClass = content ? " active" : "";
  const themeClass = theme ? " " + theme : "";

  return content !== "empty" ? (
    <StyledError
      className={"error" + themeClass + activeClass}
      theme={theme}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  ) : null;
};

const StyledError = styled.span`
  display: none;
  position: absolute;
  bottom: -32px;
  left: 0;
  z-index: 2;
  color: #e14c4c;
  font-size: 13px;
  line-height: 1.25;
  &.active {
    display: block;
  }
`;
