import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { translator } from "../../helpers/lang";
import { IconArrow } from "../Icons/IconArrow";

export const FormButton = ({ title, theme, eventHandler }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  const formButtonProps = {
    type: "submit",
    className: "button button__with-arrow",
    centered: theme,
    onClick: eventHandler,
  };

  const Button = props => {
    if (props.theme === "ipoteka") {
      return <StyledIpotekaFormButton {...formButtonProps} />;
    }
    if (props.theme === "bezzalogQuestions") {
      return <StyledBezzalogQuestionsFormButton {...formButtonProps} />;
    }
    return (
      <StyledFormButton {...formButtonProps}>
        <span>
          {title}
          <IconArrow size={20} />
        </span>
      </StyledFormButton>
    );
  };

  return <Button theme={theme} />;
};

const StyledFormButton = styled.button`
  width: 100%;
  margin: 30px 0 0;
  @media (min-width: 480px) {
    width: auto;
  }
  @media (min-width: 768px) {
    margin: 40px 0 0;
  }
`;

const StyledIpotekaFormButton = styled(StyledFormButton)`
  @media (min-width: 768px) {
    display: block;
    margin: 40px auto 0;
  }
`;

const StyledBezzalogQuestionsFormButton = styled(StyledFormButton)`
  margin: 0;
  @media (min-width: 768px) {
    margin: 0;
  }
`;
