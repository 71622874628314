import React from 'react';
import { ContactForm } from '../ContactForm';
import { ContactShortForm } from '../ContactShortForm';
import { CareerCallbackFormBlock } from '../CareerCallbackFormBlock';
import { VacancyModal } from '../VacancyModal';
import { VideoModal } from '../VideoModal';

export const RequestForm = ({ type, pageId }) => {
    if (type) {
        switch (type) {
            case 'vacancy':
                return <CareerCallbackFormBlock type={type} />;
            case 'shortForm':
                return <ContactShortForm pageId={pageId} type={type} />;
            case 'vacancyModal':
                return <VacancyModal type={type} />;
            case 'videoModal':
                return <VideoModal type={type} />;
            default:
                return <ContactForm pageId={pageId} type={type} />;
        }
    }
};
