import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { LangSwitcherLink } from "../LangSwitcherLink";

export const LangSwitcher = () => {
  const dataBase = useSelector(state => state.base);

  return (
    <StyledLangList className="lang-list">
      {dataBase.lang.all &&
        dataBase.lang.all.map((lang, i) => (
          <LangSwitcherLink key={i} lang={lang} />
        ))}
    </StyledLangList>
  );
};

const StyledLangList = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 40px 0 0;
  list-style: none;
`;
