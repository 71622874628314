import {
  checkEmailPattern,
  checkIsfilled,
  checkIsTrue,
  checkFormatFiles,
  checkSizeFiles,
} from "../helpers/inputValidator";

// Cхема для формы заявки со страницы вакансии
export const vacancyModel = [
  {
    label: {
      ru: "Имя*",
      en: "Name*",
      kz: "Аты*",
    },
    type: "text",
    name: "first_name",
    col: 12,
    validators: [
      {
        id: "name-required",
        isValidFun: checkIsfilled,
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
    ],
  },
  {
    label: {
      ru: "Фамилия*",
      en: "Last Name*",
      kz: "Аты*",
    },
    type: "text",
    name: "last_name",
    col: 12,
    validators: [
      {
        id: "name-required",
        isValidFun: checkIsfilled,
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
    ],
  },
  {
    label: {
      ru: "Email*",
      en: "Email*",
      kz: "Email*",
    },
    type: "text",
    name: "email",
    col: 12,
    validators: [
      {
        id: "email-pattern",
        isValidFun: checkEmailPattern,
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
      {
        id: "email-required",
        isValidFun: checkIsfilled,
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
    ],
  },
  {
    label: {
      ru: "Сообщение*",
      en: "Message*",
      kz: "Xабар*",
    },
    type: "text",
    name: "message_text",
    col: 12,
    validators: [
      {
        id: "name-required",
        isValidFun: checkIsfilled,
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
    ],
  },
  {
    noUpload: {
      ru: "Вы не загрузили ни один файл",
      en: "You haven't uploaded any files",
      kz: "Сіз ешқандай файл жүктеген жоқсыз",
    },
    falseMessage: {
      ru: "Можно прикрепить только файл pdf/docx/doc до 4МБ.",
      en: "You can only attach a pdf / docx / doc file up to 4MB.",
      kz: "Сіз тек pdf / docx / doc файлын 4MB дейін қоса аласыз.",
    },
    falseSize: {
      ru: "Файл должен быть не более 4 МБ.",
      en: "File size should be less than 4MB",
      kz: "Файл 4 Мбайттан аспауы керек.",
    },
    type: "file",
    name: "message_files",
    col: 12,
    check: "",
    validators: [
      {
        id: "files-required",
        isValidFun: checkFormatFiles,
        alert: {
          ru:
            "Неверный формат (допустимые форматы: pdf / docx / doc, размер файла до 4МБ.)",
          en:
            "Invalid format (valid formats: pdf / docx / doc, file size up to 4MB.)",
          kz:
            "Жарамсыз формат (жарамды форматтар: pdf / docx / doc, файл өлшемі 4МБ дейін.)",
        },
      },
      {
        id: "size-required",
        isValidFun: checkSizeFiles,
        alert: {
          ru: "Файл должен быть не более 4 МБ.",
          en: "You can attach only  a pdf / docx / doc file up to 4MB",
          kz: "Файл 4 Мбайттан аспауы керек.",
        },
      },
    ],
  },
  {
    label: {
      ru:
        "Отправляя заявку, я предоставляю согласие на обработку персональных данных",
      en: "By submitting, I consent to the processing of my personal data",
      kz:
        "Өтінімді қалдыра отырып, дербес деректерімді өңдеуге келісімімді беремін",
    },
    type: "checkbox",
    name: "check1",
    value: true,
    col: 12,
    validators: [
      {
        id: "terms-required",
        isValidFun: checkIsTrue,
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
    ],
  },
  {
    label: {
      ru: "Город*",
      en: "city*",
      kz: "Қала*",
    },
    type: "city",
    name: "city",
    hide: true,
    col: 12,
    validators: [
      {
        id: "city-required",
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
    ],
  },
  {
    label: {
      ru: "Емайл",
      en: "Email*",
      kz: "Электрондық пошта*",
    },
    type: "email",
    name: "emailVac",
    hide: false,
    col: 12,
    validators: [
      {
        id: "email-required",
        alert: {
          ru: "empty",
          en: "empty",
          kz: "empty",
        },
      },
    ],
  },
];
