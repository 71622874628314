import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { updateSubscriberModalState } from "../../store/actions/base";
import { activateSubscribeMessage } from "../../helpers/base";
import { translator } from "../../helpers/lang";

export const ModalSubscribeBlock = () => {
  const dispatch = useDispatch();

  const dataBase = useSelector(state => state.base);
  const isOpen = dataBase.subscribeModal.isOpen;
  const type = dataBase.subscribeModal.type;
  const langCurrent = dataBase.lang.current;

  return (
    <>
      {isOpen && (
        <StyledModalSubscribe className="calc-info">
          <div className="modal_window">
            <button
              dangerouslySetInnerHTML={{
                __html: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.1064 13.2274C9.93481 12.0559 8.03532 12.056 6.86378 13.2276L2.98558 17.1059C2.39972 17.6918 1.44982 17.6918 0.863992 17.1059C0.278247 16.52 0.278289 15.5703 0.864083 14.9845L4.7424 11.1062C5.91397 9.93461 5.91397 8.03511 4.7424 6.86354L0.865453 2.9866C0.279681 2.40082 0.279681 1.4511 0.865453 0.865329C1.45125 0.279536 2.40101 0.279563 2.98677 0.865386L6.86335 4.74237C8.0349 5.91405 9.93448 5.9141 11.1061 4.74248L14.9844 0.864221C15.5701 0.278445 16.5198 0.278361 17.1057 0.864032C17.6917 1.44983 17.6918 2.39979 17.106 2.98572L13.2276 6.86441C12.0561 8.036 12.0562 9.93542 13.2277 11.107L17.1073 14.9866C17.6931 15.5723 17.6929 16.522 17.1071 17.1076C16.5214 17.693 15.5721 17.6929 14.9866 17.1074L11.1064 13.2274Z" fill="#0F1A20"/>
</svg>`,
              }}
              onClick={() =>
                updateSubscriberModalState(dispatch, {
                  open: false,
                  type: null,
                })
              }
            />
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: activateSubscribeMessage(langCurrent, type),
              }}
            />
          </div>
        </StyledModalSubscribe>
      )}
    </>
  );
};

const StyledModalSubscribe = styled.div`
  overflow: hidden;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal_window {
    width: 90%;
    max-width: 400px;
    height: auto;
    background-color: #fff;
    padding: 40px;
    position: relative;
    button {
      position: absolute;
      right: 10px;
      top: 10px;
      background: transparent;
      border: none;
    }
    .content {
      width: 100%;
      height: auto;
      font-size: 26px;
      color: #0056b3;
      text-align: center;
    }
  }
`;
