import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "../../../settings";
import { translations } from "./translations";

const getTitle = (title, type, langCurrent, dataCalcLoans, dataCalcBonds) => {
  if (type === "loans") {
    const productCurrent = dataCalcLoans.currentProduct;
    const productCurrentName = dataCalcLoans.products[productCurrent].name;

    return title.loans[langCurrent].replace(
      "%productName%",
      productCurrentName
    );
  }

  if (type === "bonds") {
    const productCurrent = dataCalcBonds.currentProduct;
    const currentBond = dataCalcBonds.currentActiveBond;
    const productCurrentName =
      dataCalcBonds.products[productCurrent][currentBond].name[langCurrent];

    return title.bonds[langCurrent].replace(
      "%productName%",
      productCurrentName
    );
  }

  return title.default[langCurrent];
};

export const ContactFormTitle = ({ type }) => {
  const dataBase = useSelector(state => state.base);
  const dataCalcLoans = useSelector(state => state.calcLoans);
  const dataCalcBonds = useSelector(state => state.calcBonds);
  const langCurrent = dataBase.lang.current;
  const { title, subtitle } = translations;

  return (
    <StyledContactFormTitle id="formtop2" className="heading">
      <h2 id="formtop1" className="title h2">
        {getTitle(title, type, langCurrent, dataCalcLoans, dataCalcBonds)}
      </h2>
      <p className="subtitle">{subtitle[langCurrent]}</p>
    </StyledContactFormTitle>
  );
};

const StyledContactFormTitle = styled.div`
  padding: 0 40px 0 0;

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    padding: 0;
  }

  .title {
    color: ${COLORS.text_accent};
  }
`;
