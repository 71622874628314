// libraries
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// components
import { Col } from 'react-bootstrap';
// constants
import { BREAKPOINTS, COLORS } from '../../settings';

const clickHandler = (field, product) => {};

export const CheckboxInput = ({
    name,
    label,
    type,
    value,
    dependent,
    hidden,
    alert,
    setInputs,
    col,
    customColClass,
    theme,
    style,
    productId,
    children,
}) => {
    const dataBase = useSelector((state) => state.base);

    const langCurrent = dataBase.lang.current;
    const themeClass = theme ? ' ' + theme : '';

    return !hidden ? (
        <Col md={col} className={customColClass}>
            <StyledCheckboxInput>
                <div
                    className={
                        style
                            ? dependent
                                ? style + ' dependent' + ' checkbox' + themeClass
                                : style + ' checkbox' + themeClass
                            : 'checkbox' + themeClass
                    }
                >
                    <input
                        id={name}
                        name={name}
                        type={type}
                        value={value}
                        checked={value || false}
                        onChange={setInputs}
                        onClick={() => clickHandler(name, productId)}
                        className={alert ? 'error' : ''}
                    />
                    <label htmlFor={name} dangerouslySetInnerHTML={{ __html: label[langCurrent] }} />
                </div>
            </StyledCheckboxInput>
            {children}
        </Col>
    ) : (
        ''
    );
};

const StyledCheckboxInput = styled.div`
    .checkbox {
        margin: 30px 0 0;
        max-width: 620px;
        text-align: left;
        input {
            display: none;
            &:checked + label {
                &:before {
                    background: ${COLORS.text_accent};
                }
            }
            &.error + label {
                color: #ff3f3f;
                a {
                    color: #ff3f3f;
                }
            }
        }
        label {
            position: relative;
            min-height: 29px;
            padding: 0 0 0 44px;
            margin: 0;
            color: #205595;
            font-size: 10px;
            font-weight: 300;
            line-height: 1.25;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
                font-size: 12px;
            }
            a {
                color: inherit;
                text-decoration: underline;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
            &:before {
                content: '';
                position: absolute;
                margin-top: 5px;
                top: 0;
                left: 0;
                width: 24px;
                height: 24px;
                background: #ffffff;
                border: 2px solid ${COLORS.text_accent};
                border-radius: 4px;
                transform: translateY(0);
                transition: background 0.5s ease;
                @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                    margin-top: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &::after {
                content: '';
                position: absolute;
                margin-top: 10px;
                top: 0;
                left: 5px;
                display: block;
                width: 14px;
                height: 8px;
                border-width: 2px;
                border-style: none none solid solid;
                border-color: rgb(255, 255, 255);
                transform-origin: center center 0px;
                transform: rotate(-45deg);
                @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                    margin-top: -4px;
                    top: 50%;
                }
            }
        }
    }
    .in-body {
        max-width: none;
        padding: 16px 20px;
        border: 1px solid hsla(213, 65%, 35%, 0.2);
        border-radius: 2px;
        margin: 0 0 10px;
        label {
            padding: 0 44px 0 0;
            line-height: 29px;
            &::before {
                right: 0;
                left: auto;
            }
            &::after {
                content: 'Есть';
                position: absolute;
                right: 39px;
                display: none;
                opacity: 0.6;
            }
        }
        input:checked + label::after {
            display: inline;
        }
    }
    .ghost {
        margin: 40px 0 0;
    }
    .dependent {
        margin: -10px 0 10px;
        background: hsla(213, 65%, 35%, 0.05);
        border: none;
        border-radius: 0;
    }
`;
