// libraries
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// constants
import { BREAKPOINTS, COLORS } from '../../settings';
// hooks
import { useCitiesTranslate } from '../../hooks/use-cities-translate';
// helpers
import { translator } from '../../helpers/lang';
import { getTranslatedCityTitle } from '../../helpers/base';

export const VacancyInfo = ({ setActiveTab }) => {
    const dataBase = useSelector((state) => state.base);
    const langCurrent = dataBase.lang.current;
    const citiesTranslateList = useCitiesTranslate();
    const data = dataBase.modal.dataVacancy;
    const city = getTranslatedCityTitle(data.region, langCurrent, citiesTranslateList);

    const optionItems = [
        {
            title: city,
            subtitle: {
                ru: 'Локация:',
                en: 'Location:',
            },
        },
        {
            title: data.employment_mode,
            subtitle: {
                ru: 'Занятость:',
                en: 'Employment:',
            },
        },
        {
            title: data.expereance,
            subtitle: {
                ru: 'Опыт:',
                en: 'Experience:',
            },
        },
        {
            title: data.salary,
            subtitle: {
                ru: 'Зарплата:',
                en: 'Salary:',
            },
        },
    ];

    return (
        <>
            {data && (
                <StyledVacancyInfo className="block-container">
                    <StyledTitle
                        dangerouslySetInnerHTML={{
                            __html: data.title,
                        }}
                    />

                    <StyledMainContent>
                        <StyledSidebar>
                            <StyledOptionList>
                                {optionItems.map(({ title, subtitle }, index) => (
                                    <StyledOptionItem key={index}>
                                        <StyledOptionSubtitle
                                            dangerouslySetInnerHTML={{
                                                __html: translator(langCurrent, subtitle),
                                            }}
                                        />
                                        <StyledOptionTitle
                                            dangerouslySetInnerHTML={{
                                                __html: title,
                                            }}
                                        />
                                    </StyledOptionItem>
                                ))}
                            </StyledOptionList>

                            <StyledSidebarButton
                                onClick={() => setActiveTab(1)}
                                dangerouslySetInnerHTML={{
                                    __html: translator(langCurrent, {
                                        ru: 'Откликнуться',
                                        en: 'Respond',
                                    }),
                                }}
                            />
                        </StyledSidebar>

                        <StyledContent
                            dangerouslySetInnerHTML={{
                                __html: data.content,
                            }}
                        />

                        <StyledContentButton
                            onClick={() => setActiveTab(1)}
                            dangerouslySetInnerHTML={{
                                __html: translator(langCurrent, {
                                    ru: 'Откликнуться',
                                    en: 'Respond',
                                }),
                            }}
                        />
                    </StyledMainContent>
                </StyledVacancyInfo>
            )}
        </>
    );
};

const StyledVacancyInfo = styled.div`
    padding-top: 20px;
    background-color: #fff;
    border-radius: 8px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        padding-top: 40px;
    }
`;

const StyledTitle = styled.h2`
    font-weight: bold;
    color: #040404;
    margin-bottom: 40px;
    font-size: 25px;
    line-height: 1.2;
    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        font-size: 50px;
        line-height: 1.3;
        margin-bottom: 90px;
    }
`;

const StyledMainContent = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    button {
        justify-content: center;
        align-items: center;
        height: 50px;
        font-size: 15px;
        line-height: 1.67;
        background: #0083e5;
        padding: 14px 24px;
        border: 1px solid #0083e5;
        border-radius: 25px;
        color: #ffffff;
        text-align: center;

        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            height: 55px;
            font-size: 16px;
        }

        &:hover {
            color: #fff;
            background-color: #0062ac;
            text-decoration: none;
        }
    }
`;

const StyledSidebar = styled.div`
    width: 100%;
    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        width: 25%;
        margin-bottom: 0;
    }
`;

const StyledOptionList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 35px;
    }
`;

const StyledOptionItem = styled.div`
    width: 48%;
    height: auto;
    margin-bottom: 15px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        width: 100%;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const StyledOptionSubtitle = styled.div`
    font-size: 15px;
    line-height: 1.3;
    color: #040404;
    opacity: 0.7;
    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        font-size: 14px;
        line-height: 1.4;
    }
`;

const StyledOptionTitle = styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 1.25;
    color: #040404;
    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        font-size: 25px;
        line-height: 1.4;
    }
`;

const StyledSidebarButton = styled.button`
    display: none;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        width: fit-content;
        display: flex;
    }
`;

const StyledContentButton = styled.button`
    display: flex;
    width: 100%;
    margin-top: 20px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        display: none;
    }
`;

const StyledContent = styled.div`
    width: 100%;
    max-height: 250px;
    overflow-y: scroll;
    scrollbar-color: #ccc ${COLORS.text_accent};
    scrollbar-width: 10px;
    background-color: #f7f8fa;
    padding: 16px;

    ::-webkit-scrollbar-thumb {
        background: ${COLORS.text_accent};
        border-radius: 8px;
    }
    ::-webkit-scrollbar {
        width: 10px;
    }

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        width: 72%;
        max-height: 400px;
        padding: 8px 16px;
    }

    strong,
    b {
        font-weight: bold;
        font-size: 25px;
        line-height: 1.2;
        color: #040404;
        margin-bottom: 15px;
        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            font-size: 30px;
            line-height: 1.3;
            margin-bottom: 30px;
        }
    }

    ul {
        margin-bottom: 35px;
        padding: 0 0 0 30px;
        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            margin-bottom: 50px;
        }
        li {
            list-style-type: none;
            position: relative;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
            &:before {
                content: '—';
                position: absolute;
                display: block;
                left: -30px;
                font-size: 20px;
            }
        }
    }
`;
