import { graphql, useStaticQuery } from "gatsby";

export const useNewsList = (lang, postId = false) => {
  const { ru, en, kz } = useStaticQuery(
    graphql`
      query NewsList {
        ru: allWordpressPost(
          filter: {
            polylang_current_lang: { eq: "ru_RU" }
            status: { eq: "publish" }
          }
          sort: { order: DESC, fields: date }
        ) {
          edges {
            node {
              id
              wordpress_id
              path
              slug
              categories {
                slug
                name
              }
              #                        acf {
              #                            nsc_image {
              #                                alt_text
              #                                source_url
              #                            }
              #                        }
              featured_media {
                localFile {
                  publicURL
                }
              }
              date(formatString: "DD MMM YYYY", locale: "ru")
              title
              polylang_current_lang
            }
          }
        }

        en: allWordpressPost(
          filter: {
            polylang_current_lang: { eq: "en_US" }
            status: { eq: "publish" }
          }
          sort: { order: DESC, fields: date }
        ) {
          edges {
            node {
              id
              wordpress_id
              path
              slug
              categories {
                slug
                name
              }
              #                        acf {
              #                            nsc_image {
              #                                alt_text
              #                                source_url
              #                            }
              #                        }
              featured_media {
                localFile {
                  publicURL
                }
              }
              date(formatString: "DD MMM YYYY", locale: "en")
              title
              polylang_current_lang
            }
          }
        }

        kz: allWordpressPost(
          filter: {
            polylang_current_lang: { eq: "kk" }
            status: { eq: "publish" }
          }
          sort: { order: DESC, fields: date }
        ) {
          edges {
            node {
              id
              wordpress_id
              path
              slug
              categories {
                slug
                name
              }
              #                       acf {
              #                          nsc_image {
              #                              alt_text
              #                              source_url
              #                          }
              #                       }
              featured_media {
                localFile {
                  publicURL
                }
              }
              date(formatString: "DD MMM YYYY", locale: "kk")
              title
              polylang_current_lang
            }
          }
        }
      }
    `
  );

  function edgesToArray(edges) {
    let array = [];

    edges.forEach(
      item =>
        item.node && item.node.wordpress_id !== postId && array.push(item.node)
    );

    return array;
  }

  switch (lang) {
    case "ru":
      return edgesToArray(ru.edges);
    case "en":
      return edgesToArray(en.edges);
    case "kz":
      return edgesToArray(kz.edges);
    default:
      return edgesToArray(ru.edges);
  }
};
