import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { COLORS, BREAKPOINTS } from "../../settings";

import { Logo } from "../Logo";
import { ButtonSidebarNavClose } from "./ButtonSidebarNavClose";
import { MenuSidebar } from "./MenuSidebar";
import { LangSwitcher } from "./LangSwitcher";

export const SidebarNavBlock = () => {
  const dataBase = useSelector(state => state.base);

  return (
    <StyledSidebarNavBlock
      className="sidebar"
      isOpen={dataBase.sidebar.navOpen}
    >
      <Logo inHeader={true} inMenu={true} />
      <ButtonSidebarNavClose />
      <div className="sidebar-overflow-container">
        <div className="sidebar-data-container">
          <MenuSidebar />
          <LangSwitcher />
        </div>
      </div>
    </StyledSidebarNavBlock>
  );
};

const StyledSidebarNavBlock = styled.div`
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  max-width: 420px;
  width: 100%;
  background: ${COLORS.bg_dark};
  transform: ${props => (props.isOpen ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease-out;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    max-width: 755px;
  }

  .sidebar-overflow-container {
    overflow: auto;
    width: 100%;
    height: 100%;
  }

  .sidebar-data-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 1px; // NOTE: hack for ie
    padding: 80px 20px 50px 20px;
    align-items: flex-start;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      padding: 120px 20px 60px 50px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      padding: 100px 70px 60px;
    }
  }

  .logo {
    position: absolute;
    top: 24px;
    left: 20px;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      display: none;
    }
  }
`;
