import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { BREAKPOINTS } from "../../../../settings";
import { translator } from "../../../../helpers/lang";
import { updateSidebarSubnavState } from "../../../../store/actions/base";

import icoSubnavOpen from "../../../../images/subnav_open.svg";
import icoSubnavOpenAccent from "../../../../images/subnav_open_accent.svg";

export const ButtonSubmenuOpen = ({ folderId, folderIsOpen }) => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const translations = {
    ru: "Открыть вложенное меню",
    en: "Открыть вложенное меню",
    kz: "Открыть вложенное меню",
  };

  const clickHandler = () => {
    updateSidebarSubnavState(
      dispatch,
      dataBase.sidebar.subnavOpen !== folderId ? folderId : false
    );
  };

  return (
    <StyledOpenButton
      type="button"
      onClick={clickHandler}
      folderIsOpen={folderIsOpen}
      aria-label={translator(langCurrent, translations)}
    />
  );
};

const StyledOpenButton = styled.button`
  position: relative;
  display: inline-block;
  flex-grow: 1;
  background: transparent;
  padding: 0;
  border: none;
  text-align: right;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    display: none;
  }

  &:hover {
    &::before {
      background-image: url(${icoSubnavOpenAccent});
    }
  }

  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 30px;
    height: 30px;
    margin: -15px 0 0;
    background: url(${icoSubnavOpen}) center/contain no-repeat;
    transform: ${props => (props.folderIsOpen ? "scaleY(-1)" : "scale(1)")};
  }
`;
