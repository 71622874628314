export const translations = {
  title: {
    loans: {
      ru: `Оставить заявку для %productName%`,
      en: `Submit an application for the %productName%`,
      kz: `%productName% үшін өтініш қалдырыңыз`,
    },
    bonds: {
      ru: `Оставить заявку на %productName%`,
      en: `Submit an application for the %productName%`,
      kz: `%productName% үшін өтініш қалдырыңыз`,
    },
    default: {
      ru: "Оставить заявку",
      en: "Submit an application",
      kz: "Өтінім қалдыру",
    },
  },
  subtitle: {
    ru: "Заполните заявку, и мы свяжемся с вами в ближайшее время",
    en:
      "Fill in the application form and we will contact you as soon as possible",
    kz: "Өтінімді қалдырыңыз және біз сізбен жақын арада хабарласамыз",
  },
};
