import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { translator } from "../../helpers/lang";

export const Loader = () => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  return (
    <StyledLoader id="global-loader">
      {translator(langCurrent, {
        ru: "Отправка запроса ...",
        en: "Submitting a request ...",
        kz: "Сұрау жіберу ...",
      })}
    </StyledLoader>
  );
};

const StyledLoader = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
`;
