import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { SETTINGS } from "../../settings";
import {
  setCookie,
  setCookiesFromUrlParams,
  checkUrlOnSubscribe,
} from "../../helpers/cookies";
// import { getDataToSend } from "../../helpers/getDataToSend";
import { langNormalise, langsNormalise } from "../../helpers/lang";
import { getCookie } from "../../helpers/cookies";
import {
  updateLang,
  updateLangs,
  updatePageType,
  setIP,
} from "../../store/actions/base";
import { GlobalStyle } from "./GlobalStyles";
import { BlocksOrderStyles } from "./BlocksOrderStyles";
import { HelmetBlock } from "../HelmetBlock";
import { HeaderBlock } from "../HeaderBlock";
import { CookieBanner } from "../CookieBanner";
import { FooterBlock } from "../FooterBlock";
import { SidebarNavBlock } from "../SidebarNavBlock";
import { ModalSubscribeBlock } from "../ModalSubscribeBlock";
import { Overlay } from "../Overlay";
import { ModalBlock } from "../Modal";

export const Layout = ({ data, children }) => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const modal = dataBase.modal.open;

  const lang = langNormalise(data.polylang_current_lang);

  const langs = langsNormalise(
    data.polylang_translations,
    lang,
    SETTINGS.LANGS
  );
  const isPageMain = SETTINGS.PAGE_MAIN.includes(data.wordpress_id);

  useEffect(() => {
    updateLang(dispatch, lang);
    updateLangs(dispatch, langs);
    updatePageType(dispatch, {
      isPageMain: isPageMain,
      isPageError: false,
    });

    if (!dataBase.ip) {
      // fetch(API.GET_IP)
      //   .then(res => res.json())
      //   .then(data => {
      //     if (data && data.ip) {
      //       setIP(dispatch, data.ip);
      //     }
      //   });

      setIP(dispatch, "-");
    }

    const dataUser = {
      cqUser: typeof window !== `undefined` && getCookie("carrotquest_uid"),
    };

    // if (dataUser.cqUser) {
    //   getDataToSend(API.GET_USER_DATA, API.HEADERS.POST, dispatch, dataUser);
    // }
  }, []);

  useEffect(() => {
    setCookiesFromUrlParams();
    checkUrlOnSubscribe(dispatch);
    // setCookie("language", lang.toUpperCase());
    // setCookie("locale", data.polylang_current_lang.slice(0, 2));
    if (window.location.search.slice(1)) {
      setCookie("utms", window.location.href);
    }
  }, []);

  useEffect(() => {
    if (modal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [modal]);

  let className = "layout";
  if (isPageMain) className += " layout__main";

  return (
    <StyledLayout className={className}>
      <GlobalStyle />
      <BlocksOrderStyles />

      <HelmetBlock data={data} />
      <CookieBanner />
      <HeaderBlock />
      <main>{children}</main>
      <FooterBlock />
      <SidebarNavBlock />
      {data.type === "page" ? <ModalBlock pageId={data.wordpress_id} /> : null}
      <ModalSubscribeBlock />
      <Overlay />
    </StyledLayout>
  );
};

const StyledLayout = styled.div`
  position: relative;
  overflow: hidden;
`;
