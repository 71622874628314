import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { RequestForm } from '../RequestForm';
import { updateModalState } from '../../store/actions/base';

export const ModalBlock = ({ pageId }) => {
    const dispatch = useDispatch();
    const dataBase = useSelector((state) => state.base);

    const buttonClick = () => {
        updateModalState(dispatch, { type: null });
    };

    return dataBase.modal.type ? (
        <StyledModal className="calc-info" formOpen={dataBase.modal.open}>
            <StyledBackdrop onClick={() => buttonClick()} formOpen={dataBase.modal.open} />
            <RequestForm type={dataBase.modal.type} pageId={pageId} />
        </StyledModal>
    ) : null;
};

const StyledModal = styled.div`
    overflow: auto;
    position: fixed;
    z-index: 1100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${(props) => (props.formOpen ? 'block' : 'none')};
`;

const StyledBackdrop = styled.div`
    pointer-events: ${(props) => (props.formOpen ? 'auto' : 'none')};
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) => (props.formOpen ? 'hsla(0, 0%, 0%, 0.75)' : 'hsla(0, 0%, 0%, 0)')};
    transition: background 0.3s ease;
`;
