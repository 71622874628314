import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "gatsby";
import { SETTINGS, COLORS, BREAKPOINTS } from "../../../settings";
import { addItemClass } from "../../../helpers/nav";
import { langGetNavItems } from "../../../helpers/lang";
import { useMenuSidebar } from "../../../hooks/use-menu-sidebar";
import { updateSidebarNavState } from "../../../store/actions/base";
import { ButtonSubmenuOpen } from "./ButtonSubmenuOpen";

export const MenuSidebar = () => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const itemsAll = useMenuSidebar();
  const baseItems = langGetNavItems(itemsAll, "base", langCurrent);
  const additionalItems = langGetNavItems(itemsAll, "additional", langCurrent);

  const submenuIsOpen = (currentId, openedId) => currentId === openedId;
  const getPath = menuItem => menuItem.url.replace(SETTINGS.URL_MASTER, "");

  return (
    <StyledMenu className="menu-sidebar">
      {baseItems && (
        <ul className="menu menu-sidebar_base">
          {baseItems.map(item => {
            const path = getPath(item);
            const haveChildren = !!item.wordpress_children;
            const submenuClass = submenuIsOpen(
              item.wordpress_id,
              dataBase.sidebar.subnavOpen
            )
              ? " menu_submenu__open"
              : "";

            return (
              <li
                key={item.wordpress_id}
                className={addItemClass(haveChildren)}
              >
                <Link
                  to={path}
                  className="menu_link"
                  onClick={() => updateSidebarNavState(dispatch)}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                  partiallyActive={true}
                  activeClassName="menu_link__active"
                />
                {haveChildren && (
                  <ButtonSubmenuOpen
                    folderId={item.wordpress_id}
                    folderIsOpen={submenuIsOpen(
                      item.wordpress_id,
                      dataBase.sidebar.subnavOpen
                    )}
                  />
                )}
                {haveChildren && (
                  <ul className={"menu_submenu" + submenuClass}>
                    {item.wordpress_children.map(subItem => {
                      const path = getPath(subItem);

                      return (
                        <li key={subItem.wordpress_id} className="menu_item">
                          <Link
                            to={path}
                            className="menu_link"
                            onClick={() => updateSidebarNavState(dispatch)}
                            dangerouslySetInnerHTML={{ __html: subItem.title }}
                            partiallyActive={true}
                            activeClassName="menu_link__active"
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      )}

      {additionalItems && (
        <ul className="menu menu-sidebar_additional">
          {additionalItems.map(item => (
            <li key={item.wordpress_id} className="menu_item">
              <Link
                to={item.url.replace(SETTINGS.URL_MASTER, "")}
                className="menu_link"
                onClick={() => updateSidebarNavState(dispatch)}
                dangerouslySetInnerHTML={{ __html: item.title }}
                partiallyActive={true}
                activeClassName="menu_link__active"
              />
            </li>
          ))}
        </ul>
      )}
    </StyledMenu>
  );
};

const StyledMenu = styled.div`
  width: 100%;
  margin: 0 0 auto;

  .menu_item {
    font-size: 16px;
    line-height: 25px;
  }

  .menu_link {
    color: ${COLORS.text_inverted};
    text-decoration: none;
    transition: color 0.15s ease-out;

    &:hover,
    &:focus {
      color: ${COLORS.text_accent};
    }

    &__active {
      color: ${COLORS.text_accent};
    }
  }

  .menu-sidebar_base {
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      margin: calc(-15px / 2) 0;
    }

    > .menu_item {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      font-size: 25px;
      line-height: 35px;
      font-weight: 700;

      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        display: block;
        width: 55%;
        padding: calc(15px / 2) 0;
        font-size: 30px;
        line-height: 40px;
      }

      &:hover,
      &:focus {
        > .menu_link {
          @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
            color: ${COLORS.text_accent};
          }
        }

        .menu_submenu {
          @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
            pointer-events: auto;
            opacity: 1;
          }
        }
      }

      // &.menu_item__folder-open {
      //   > .menu_link {
      //     color: ${COLORS.text_accent};
      //     transition-duration: 0s;
      //   }
      // }

      + .menu_item {
        margin: 15px 0 0;

        @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
          margin: 0;
        }
      }
    }

    .menu_submenu {
      display: none;
      width: 100%;
      margin: 15px 0 5px;

      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        margin: 20px 0 10px;
      }

      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        pointer-events: none;
        position: absolute;
        top: 16px;
        left: 99%;
        opacity: 0;
        display: block;
        width: calc(100% * 45 / 55);
        margin: 0;
      }

      &.menu_submenu__open {
        display: block;
      }

      .menu_item {
        font-weight: 400;

        + .menu_item {
          margin: 10px 0 0;
        }
      }
    }
  }

  .menu-sidebar_additional {
    margin: 30px 0 0;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin: 40px 0 0;
    }

    > .menu_item {
      + .menu_item {
        margin: 15px 0 0;
      }
    }
  }
`;
