import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { BREAKPOINTS } from "../../settings";
import { translations } from "./translations";

export const CookieBanner = () => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const [showBanner, setShowBanner] = useState(false);
  const { cookieBannerText, cookieBannerButton } = translations;

  useEffect(() => {
    if (localStorage.getItem("cookieBanner") === "hide") {
      return;
    }
    setShowBanner(true);
  }, []);

  const closeCookieBanner = () => {
    localStorage.setItem("cookieBanner", "hide");
    setShowBanner(false);
  };

  return showBanner ? (
    <StyledCookieBanner className="cookie-banner">
      <div className="cookie-banner__container element-container">
        <div className="cookie-banner__panel">
          <div
            className="cookie-banner__content"
            dangerouslySetInnerHTML={{ __html: cookieBannerText[langCurrent] }}
          />
          <button
            className="cookie-banner__button button"
            dangerouslySetInnerHTML={{
              __html: cookieBannerButton[langCurrent],
            }}
            onClick={() => closeCookieBanner()}
          />
        </div>
      </div>
    </StyledCookieBanner>
  ) : null;
};

const StyledCookieBanner = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 10;

  .cookie-banner__container {
    padding-bottom: 7px;

    // TODO: max-width что бы не переназначать заново общие стили для
    //  контейнера .element-container
    @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
      padding: 0;
    }
  }

  .cookie-banner__panel {
    align-items: center;
    background: white;
    border-radius: 7px 7px 0 0;
    box-shadow: 0 0 10px rgba(45, 59, 77, 0.25);
    display: flex;
    font-size: 12px;
    line-height: 16px;
    padding: 10px;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
      align-items: flex-start;
      border-radius: 7px;
      font-size: 16px;
      line-height: 25px;
    }
  }

  .cookie-banner__button {
    border-radius: 7px;
    flex-shrink: 0;
    margin: 0 0 0 20px;

    // TODO: max-width что бы не переназначать заново общие стили для
    //  кнопок .button
    @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
      font-size: 12px;
      line-height: 16px;
      padding: 7px 14px;
    }
  }
`;
