import React from "react";

import { FieldsetInput } from "../components/FieldsetInput";
import { FileInput } from "../components/FileInput";
import { EmailInput } from "../components/EmailInput";
import { CityInput } from "../components/CityInput";
import { CheckboxInput } from "../components/CheckboxInput";
import { TextInput } from "../components/TextInput";
import { TextareaInput } from "../components/TextareaInput";
import { Row } from "react-bootstrap";

const capitalize = expression =>
  expression.charAt(0).toUpperCase() + expression.slice(1);

const Components = {
  FieldsetInput,
  TextInput,
  CheckboxInput,
  TextareaInput,
  CityInput,
  EmailInput,
  FileInput,
};

export const renderInput = (input, setInputs, calc, callback, label) => {
  if (input.type !== "info") {
    const Component = Components[capitalize(input.type) + "Input"];

    return (
      <Component
        key={input.name}
        setInputs={setInputs}
        {...input}
        productId={calc}
        callback={callback}
        labelNew={label}
      >
        {input.fields && (
          <Row>
            {input.fields.map(subInput =>
              renderInput(subInput, setInputs, calc)
            )}
          </Row>
        )}
      </Component>
    );
  }
};
