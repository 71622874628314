import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FORM } from '../settings';
import { contactsModel } from '../models/contactsModel';
import { contactsShortModel } from '../models/contactsShortModel';
import { bondsModel } from '../models/bondsModel';
import { vacancyModel } from '../models/vacancyModel';
import { vacanciesModel } from '../models/vacanciesModel';

export const useForm = (type, submitCallback, additionalData, setPageScroll, autofill) => {
    const dataBase = useSelector((state) => state.base);
    const langCurrent = dataBase.lang.current;

    let initModel = {};
    switch (type) {
        case 'bonds':
            initModel = bondsModel;
            break;
        case 'loans':
            initModel = contactsModel;
            break;
        case 'shortForm':
            initModel = contactsShortModel;
            break;
        case 'vacancy':
            initModel = vacancyModel;
            break;
        case 'vacancies':
            initModel = vacanciesModel;
            break;
        default:
            initModel = contactsModel;
    }

    const [inputs, setInputs] = useState(initModel);
    const [message, setMessage] = useState({
        type: null,
        text: null,
    });

    useEffect(() => {
        setInputs(initModel);
    }, [initModel]);

    const validateInput = (input) => {
        let alert = null;

        input.validators &&
            input.validators.forEach(
                (v) => (alert = v.isValidFun && !v.isValidFun(input.value) ? v.alert[langCurrent] : alert)
            );

        input.alert = alert;
    };

    const validateInputs = (input) => {
        let alert = null;

        input.validators &&
            input.validators.forEach(
                (v) => (alert = v.isValidFun && !v.isValidFun(input.value) ? v.alert[langCurrent] : alert)
            );

        input.alert = alert;

        input.fields && input.fields.forEach((subInput) => validateInputs(subInput));
    };

    const validateInputFiles = (input) => {
        let alert = null;
        let formatFileValid = false;
        
        input.validators &&
            input.validators.forEach((v) => {
                if (v.id === 'files-required') {
                    if (v.isValidFun && v.isValidFun(input.value)) {
                        formatFileValid = true;
                        input.check = '_true';
                        alert = alert;
                    } else if (input.value === undefined) {
                            input.check = '_true';
                            alert = '';
                    } else {
                        input.check = '_false _false-file';
                        alert = v.alert[langCurrent];
                    }
                }

                if (v.id === 'size-required' && formatFileValid === true) {
                    if (v.isValidFun && v.isValidFun(input.size)) {
                        input.check = '_true';
                        alert = alert;
                    } else {
                        input.check = '_false _false-file';
                        alert = v.alert[langCurrent];
                    }
                }
            });
        input.alert = alert;
    };

    const findErrors = (inputs) => {
        let haveError = false;

        inputs.forEach((input) => {
            if (input.alert && !input.hidden) {
                haveError = true;
            }

            if (input.fields && findErrors(input.fields)) {
                haveError = true;
            }
        });

        return haveError;
    };

    if (additionalData !== null && autofill && autofill.autofillInputs) {
        inputs.forEach((input) => {
            if (input.name === 'amount' && additionalData.currentVal) {
                input.value = additionalData.currentVal;
                validateInput(input);
            }
            if (input.name === 'currency' && additionalData.currentCurrency) {
                input.value = additionalData.currentCurrency.toUpperCase();
                validateInput(input);
            }
            if (input.name === 'term' && additionalData.currentTerm) {
                input.value = additionalData.currentTerm;
                validateInput(input);
            }
            if (input.name === 'description' && additionalData.currentProduct) {
                input.value = additionalData.currentProduct;
                validateInput(input);
            }
        });

        autofill.setAutofillInputs(false);
    }

    const resetForm = (labelText) => {
        const resetFields = (inputs) => {
            inputs.forEach((i) => {
                if (i.type === 'select') {
                    i.value = i.options[0].value;
                    i.alert = null;
                } else if (i.type === 'checkbox' && i.name !== 'terms') {
                    i.value = false;
                    i.alert = null;
                } else if (i.type === 'checkbox' && i.name === 'terms') {
                    i.value = true;
                    i.alert = null;
                } else if ((i.type === 'text' || i.type === 'textarea') && i.name !== 'expVar') {
                    i.value = '';
                    i.alert = null;
                } else if (i.type === 'file') {
                    i.value = labelText;
                    i.check = '';
                }

                i.fields && resetFields(i.fields);
            });
        };

        resetFields(inputs);

        setInputs([...inputs]);
    };

    const handleChange = (e, meta, size) => {
        const updateInputs = (inputs) => {
            inputs.forEach((i) => {
                if (meta && i.name === meta.name && meta !== 'file') {
                    i.value = e.value;

                    parseInput(i);
                    checkChildrenState(i);
                    validateInput(i);
                } else if (e.target && meta !== 'file') {
                    if (i.type !== 'file' && i.type !== 'fieldset' && i.name === e.target.name) {
                        i.value = i.type === 'checkbox' ? e.target.checked : e.target.value;

                        parseInput(i);
                        checkChildrenState(i);
                        validateInput(i);
                    } else {
                        i.fields && updateInputs(i.fields);
                    }
                } else if (e.target && meta === 'file') {
                    if (i.name === e.target.name) {
                        i.value = e.target.value;
                        i.size = size;
                        checkFileFormat(i);
                    }
                }
            });
        };
        updateInputs(inputs);

        setInputs([...inputs]);
    };

    const handleSubmit = (e) => {
        e && e.preventDefault();
        inputs.forEach((i) => (i.type !== 'file' ? validateInputs(i) : checkFileFormat(i)));

        if (findErrors(inputs)) {
            setInputs([...inputs]);
            setMessage({
                type: 'error',
                text: FORM.FORM_INCORRECT[langCurrent],
            });
            setPageScroll('#formtop1');
            if (window.ym) {
                window.ym(67027090, 'reachGoal', 'app_sent');
                window.ym(67027090, 'reachGoal', 'all_sent_app');
            }
        } else {
            submitCallback(e);
            setPageScroll('#formtop2');
        }
    };

    const parseInput = (input) => {
        input.value = input.parseFun ? input.parseFun(input.value) : input.value;
    };

    const checkFileFormat = (input) => {
        validateInputFiles(input);
    };

    const checkChildrenState = (input) => {
        const name = input.name;
        const value = input.value;

        const updateInputsState = (inputs) => {
            inputs.forEach((input) => {
                if (input.dependent && input.dependent[name] === value) {
                    input.hidden = false;
                }

                if (input.dependent && input.dependent[name] !== value) {
                    input.hidden = true;
                }

                input.fields && updateInputsState(input.fields);
            });
        };

        input.fields && updateInputsState(input.fields);
    };

    return [inputs, handleChange, handleSubmit, resetForm, message, setMessage];
};
