// libraries
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
// components
import { BREAKPOINTS } from '../../settings';
import { VacancyInfo } from '../VacancyInfo';
import { VacancyForm } from '../VacancyForm';
// helpers
import { translator } from '../../helpers/lang';
// store
import { updateModalState } from '../../store/actions/base';
// static
import icoNavCloseMobile from '../../images/nav_close_mobile.svg';

export const VacancyModal = () => {
    const dataBase = useSelector((state) => state.base);
    const langCurrent = dataBase.lang.current;

    const [activeIndex, setActiveIndex] = useState(0);

    const dispatch = useDispatch();

    const buttonClick = () => {
        updateModalState(dispatch, { type: null });
    };

    const setActiveTab = (index) => {
        setActiveIndex(index);
    };

    return (
        <StyledVacancyModal>
            <StyledTabList>
                <StyledTabButton
                    className={activeIndex === 0 ? 'active' : ''}
                    aria-label="shows job information"
                    dangerouslySetInnerHTML={{
                        __html: translator(langCurrent, {
                            ru: 'Информация',
                            en: 'Information',
                        }),
                    }}
                    onClick={() => {
                        setActiveTab(0);
                    }}
                />
                <StyledTabButton
                    className={activeIndex === 1 ? 'active' : ''}
                    aria-label="open form feedback"
                    dangerouslySetInnerHTML={{
                        __html: translator(langCurrent, {
                            ru: 'Отклик',
                            en: 'Feedback',
                        }),
                    }}
                    onClick={() => {
                        setActiveTab(1);
                    }}
                />
            </StyledTabList>
            <StyledContentPart>
                {activeIndex === 1 ? <VacancyForm type="vacancies" /> : <VacancyInfo setActiveTab={setActiveTab} />}
            </StyledContentPart>
            <StyledCloseBtn onClick={buttonClick} aria-label="close modal window" />
        </StyledVacancyModal>
    );
};

const StyledVacancyModal = styled.div`
    position: relative;
    max-width: 95%;
    margin: 40px auto 0;
    background-color: #fff;
    border-radius: 8px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        max-width: 992px;
    }
`;

const StyledCloseBtn = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    background: #0083e5;
    padding: 0;
    border: none;
    border-top-right-radius: 5px;

    &:hover,
    &:focus {
        &::before {
            transform: rotate(180deg);
        }
    }

    &::before {
        content: '';
        width: 40px;
        height: 40px;
        background: url(${icoNavCloseMobile}) center/contain no-repeat;
        transform: rotate(0);
        transform-origin: center;
        transition: transform 0.3s ease-in-out;
    }
`;

const StyledTabList = styled.div`
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
    border-radius: 8px;
`;

const StyledTabButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    min-width: auto;
    height: 40px;
    outline: none;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: -0.3px;
    color: #000;
    opacity: 0.5;
    white-space: nowrap;
    cursor: pointer;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        min-width: 200px;
    }

    &.active {
        opacity: 1;
        background-color: #fff;
    }

    &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #fff;
        content: '';
    }

    &:focus,
    &:hover {
        outline: none;

        &:not(.active) {
            opacity: 0.7;
        }
    }
`;

const StyledContentPart = styled.div`
    height: auto;
`;
