import { useStaticQuery, graphql } from "gatsby";

export const useCitiesTranslate = () => {
  const { allWordpressAcfOptions } = useStaticQuery(
    graphql`
      query Options {
        allWordpressAcfOptions {
          nodes {
            options {
              cities {
                city {
                  lang
                  title
                }
              }
            }
          }
        }
      }
    `
  );

  return allWordpressAcfOptions.nodes[0].options.cities;
};
