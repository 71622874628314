export const checkIsNumber = number => {
  const regex = /^[0-9]+$/;
  return regex.test(number) || !number;
};

export const checkAtLeastLength = (expression, length) =>
  expression && expression.trim().length >= length;

export const checkIsfilled = expression => {
  return expression && expression.toString().length > 0;
};

export const checkIsTrue = expression => expression;

export const checkEmailPattern = mail => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(mail);
};

export const checkFormatFiles = file => {
  const regex = /\.(doc|docx|pdf)$/i;
  return regex.test(file);
};

export const checkSizeFiles = file => {
  return Number(file) < 2 * 1024 * 1024;
};
