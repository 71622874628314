import React from "react";

import { COLORS } from "../../../settings";

export const IconArrow = ({ size }) => {
  return (
    <svg
      width={size || "30"}
      height={size || "30"}
      viewBox="0 0 30 30"
      className="icon icon__arrow"
    >
      <path
        stroke={COLORS.text}
        strokeLinecap="round"
        strokeWidth="3"
        d="M2 15.4h24"
      />
      <path
        stroke={COLORS.text}
        strokeWidth="2"
        d="M17.4 24.8v0l.002.002a.5.5 0 00.706 0V24.8l9-9a.502.502 0 00.11-.544.49.49 0 00-.11-.162l-9-9a.502.502 0 00-.854.354.501.501 0 00.147.354l8.64 8.64-8.64 8.64H17.4a.5.5 0 000 .706z"
      />
    </svg>
  );
};
