// libraries
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from 'styled-components';
// components
import { Loader } from '../Loader';
import { FormButton } from '../FormButton';
// constants
import { API, FORM, BREAKPOINTS, COLORS } from '../../settings';
// helpers
import { renderInput } from '../../helpers/renderInput';
import { translator } from '../../helpers/lang';
// hooks
import { useForm } from '../../hooks/use-form';

const checkLang = {
    ru: 'Перетащите и поместите файл или&nbsp;<span>нажмите</span>&nbsp;чтобы загрузить',
    en: 'Upload the file',
};
const checkUploadLang = {
    ru: 'Нажмите, чтобы загрузить файл',
    en: 'Click to download the file',
};

export const VacancyForm = ({ type }) => {
    const dataBase = useSelector((state) => state.base);
    const langCurrent = dataBase.lang.current;
    const [isLoading, setIsLoading] = useState(false);
    const [isPageScroll, setIsPageScroll] = useState(false);
    const [inputLabel, setInputLabel] = useState(checkLang[langCurrent]);

    const inputEl = useRef(null);

    const getRecaptchaToken = () => {
        typeof window !== 'undefined' &&
            window.grecaptcha.ready(async () => {
                const response = await window.grecaptcha.execute('6LdlFF0gAAAAAGN7qZCPcfABsf6NJSGADIIeGsqS', {
                    action: 'submit',
                });
                const token = await response;

                sendForm(token);
            });
    };

    const sendForm = (token) => {
        setIsLoading(true);

        let formData = new FormData(inputEl.current);
        formData.append('recaptchaToken', token);
        formData.append('vacancyName', dataBase.modal.dataVacancy.title);
        formData.append('city', dataBase.modal.dataVacancy.city);
        
        fetch(API.SEND_REQUEST_VACANCY, {
            ...API.HEADERS.POST,
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                setIsLoading(false);
                if (data && data.status) {
                    setMessage({
                        type: 'success',
                        text: FORM.FORM_SUCCESS[langCurrent],
                    });
                    resetForm();
                    setInputLabel(checkUploadLang[langCurrent]);
                    inputEl.current.message_files.value = '';
                    setIsPageScroll(false);
                } else {
                    setMessage({
                        type: 'error',
                        text: FORM.FORM_ERROR[langCurrent],
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const [inputs, setInputs, setSubmit, resetForm, message, setMessage] = useForm(
        type,
        getRecaptchaToken,
        null,
        setIsPageScroll
    );

    useEffect(() => {
        if (isPageScroll) {
            scrollTo(isPageScroll);
        }
    }, [isPageScroll]);

    return (
        <StyledVacancyForm message={message}>
            <p className="subtitle">
                {translator(langCurrent, {
                    ru: 'Напишите нам, и мы свяжемся с вами в ближайшее время',
                    en: 'Fill in the application form and we will contact you as soon as possible',
                })}
            </p>
            {message.type && message.type === 'success' && (
                <StyledFormMessage className={message.type ? message.type : ''}>{message.text}</StyledFormMessage>
            )}
            <StyledFormBlock>
                {isLoading && <Loader />}
                <form ref={inputEl}>
                    <StyledGridInputs>
                        {inputs.map((input) => renderInput(input, setInputs, null, setInputLabel, inputLabel))}
                    </StyledGridInputs>
                    <FormButton
                        title={translator(langCurrent, {
                            ru: 'Отправить',
                            en: 'Send message',
                        })}
                        eventHandler={setSubmit}
                    />
                </form>
            </StyledFormBlock>
        </StyledVacancyForm>
    );
};

const StyledFormMessage = styled.div`
    padding: 15px;
    margin: 40px 0 0;
    border: 2px solid transparent;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    color: #9edc15;

    &.success {
        color: #0b70d6;
        border-color: #0b70d6;
    }

    &.error {
        color: #ff3f3f;
        border-color: #ff3f3f;
    }
`;

const StyledVacancyForm = styled.div`
    pointer-events: auto;
    position: relative;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        padding: 40px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
        margin: 20px auto;
    }

    .subtitle {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 1.2;

        @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
            font-size: 36px;
        }
    }

    form {
        display: block;
    }

    ${StyledFormMessage} {
        display: ${(props) => (props.message.type ? 'block' : 'none')};
    }

    .input  {
        input, 
        textarea {
            margin: 0;
            margin-bottom: 16px;
            padding: 10px;
            background: #f7f8fa;
            opacity: 0.8;
            border-bottom: 1px solid rgba(152, 152, 152, 0.8);
            font-size: 16px;

            &::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 1.4;
                text-align: left;
                letter-spacing: -0.5px;
            }

            &:focus {
                &::placeholder {
                    color: ${COLORS.text_accent};
                }
            }
        }

        &.textarea {
            label,
            &::after {
                display: none;
            }
        }
    }

    .fileInput {
        label {
            font-size: 12px;
            line-height: 1.4;
            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                font-size: 14px;
            }

            span {
                color: ${COLORS.text_accent};
                text-decoration: underline;

                &::after {
                    content: ",";
                    color: #898989;
                }
            }
        }
    }

    .messageInput {
        position: relative;

        textarea {
            max-height: 120px;

            &::placeholder {
                position: absolute;
                top: 10px;
                left: 10px;
            }
        }
    }
`;

const StyledFormBlock = styled.div`
    margin: 40px 0 0;
`;

const StyledGridInputs = styled.div`
    display: grid;
    gap: 16px;
    grid-template-areas:
        'firstName'
        'lastName'
        'email'
        'messageText'
        'file'
        'checkbox';

    @media (min-width: ${BREAKPOINTS.mobile + 'px'}) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-auto-columns: 1fr;
        gap: 0px 50px;
        grid-auto-flow: column;
        justify-items: stretch;
        align-items: stretch;
        grid-template-areas:
            'firstName file'
            'lastName file'
            'email file'
            'messageText file'
            'checkbox checkbox';
        height: 355px;
    }

    .firstName {
        grid-area: firstName;
        padding: 0;
        max-height: 40px;
    }

    .lastName {
        grid-area: lastName;
        padding: 0;
        max-height: 40px;
    }

    .email {
        grid-area: email;
        padding: 0;
        max-height: 40px;
    }

    .messageText {
        grid-area: messageText;
        padding: 0;
        height: 120px;
    }

    .file {
        align-self: stretch;
        padding: 0;
        grid-area: file;
        height: 160px;

        @media (min-width: ${BREAKPOINTS.mobile + 'px'}) {
            height: 100%;
        }
    }

    .checkbox {
        grid-area: checkbox;
        padding: 0;

        label {
            display: flex;
            align-items: center;
        }
    }
`;
