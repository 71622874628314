export const translations = {
  cookieBannerText: {
    ru:
      "Используя сайт idfeurasia.com я даю согласие на обработку моих персональных данных с использованием cookies, сервисов аналитики и принимаю условия <a href='/files/politika-konfidentsialnosti.pdf' target='_blank'>Политики&nbsp;конфиденциальности</a>.",
    en:
      "By using idfeurasia.com website I agree to the terms of the <a href='/files/politika-konfidentsialnosti.pdf' target='_blank'>Privacy&nbsp;Policy</a> and the processing of my personal data using cookies, analytics services.",
    kz:
      "idfeurasia.com сайтын пайдалана отырып, cookies аналитика сервистері арқылы, менің дербес деректерімді өңдеуге келісім беремін және <a href='/files/politika-konfidentsialnosti.pdf' target='_blank'>Құпиялылық&nbsp;саясатының</a> шарттарын қабылдаймын.",
  },
  cookieBannerButton: {
    ru: "Я согласен",
    en: "I agree",
    kz: "Мен келісемін",
  },
};
