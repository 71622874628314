import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { BREAKPOINTS, COLORS } from "../../../settings";
import { hexToRgb } from "../../../helpers/base";
import { translator } from "../../../helpers/lang";
import { updateSidebarNavState } from "../../../store/actions/base";

import { Translator } from "../../Translator";

import icoNavClose from "../../../images/nav_close.svg";
import icoNavCloseMobile from "../../../images/nav_close_mobile.svg";

export const ButtonSidebarNavClose = () => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  const ClickHandler = () => {
    updateSidebarNavState(dispatch);
  };

  return (
    <StyledButton
      type="button"
      onClick={ClickHandler}
      className="cros-wrapper"
      isPageMain={dataBase.pages.isPageMain}
      aria-label={translator(langCurrent, {
        ru: "Закрыть",
        en: "Close",
        kz: "Жабу",
      })}
    >
      <StyledText>
        <Translator
          langCode={langCurrent}
          translations={{ ru: "Закрыть", en: "Close", kz: "Жабу" }}
        />
      </StyledText>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  display: flex;
  align-items: center;
  background: rgba(${hexToRgb(COLORS.bg_dark).toString()}, 0.8);
  padding: 0;
  border: none;
  box-shadow: 0 0 16px 16px rgba(${hexToRgb(COLORS.bg_dark).toString()}, 0.8);
  color: ${COLORS.text_inverted};
  font-size: 16px;
  line-height: 25px;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    top: 30px;
    right: 20px;
    padding: 0 34px 0 0;
  }

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    top: 40px;
    right: 70px;
  }

  &:hover,
  &:focus {
    &::before {
      transform: rotate(180deg);
    }
  }

  &::before {
    content: "";

    width: 24px;
    height: 24px;
    background: url(${icoNavCloseMobile}) center/contain no-repeat;

    transform: rotate(0);
    transform-origin: center;
    transition: transform 0.3s ease-in-out;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      position: absolute;
      right: 0;
      bottom: 0;
      background-image: url(${icoNavClose});

      transform-origin: 11px 13px;
    }
  }
`;

const StyledText = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    display: block;
  }
`;
